import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SERVER_URL } from './Constant/Constant';
import axios from 'axios';

function Notifyer({ noti, user ,fetchnoticount }) {
  useEffect(() => {
    markAllAsRead();
   
    
  }, []);

  const markAllAsRead = async () => {
    const studentIds = noti.map(student => student._id);
  
    try {
      // Make a batch update request to mark all students as read
      await axios.put(`${SERVER_URL}/api/students/read`, {
        studentIds,
        employeeId: user.id, // No need to pass employeeId since it's already available in the component
        read: true
      });
  
      // After successful update, fetch notifications again
      fetchnoticount(user);
    } catch (error) {
      console.error('Error marking all students as read:', error);
    }
  };
  
  const markasunread = async () => {
    const studentIds = noti.map(student => student._id);

    try {
      // Make a batch update request to mark all students as read
      await axios.put(`${SERVER_URL}/api/students/read`, {
        studentIds,
        employeeId :user.id, // No need to pass employeeId since it's already available in the component
        read: false
      });
      fetchnoticount(user);

    } catch (error) {
      console.error('Error marking all students as read:', error);
    }
  };

  return (
    <div className='notificationdiv'>
      <button className='btn btn-warning mt-4' onClick={markasunread}>Mark As Unread</button>
      {
        noti.map((notifi) => (
          <div className='singlenotification ' key={notifi._id}>
            <div className='d-flex gap-2 align-items-center firstdiv'>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M6.8125 25.1875C5.6625 24.0375 6.425 21.625 5.8375 20.2125C5.25 18.8 3 17.5625 3 16C3 14.4375 5.225 13.25 5.8375 11.7875C6.45 10.325 5.6625 7.9625 6.8125 6.8125C7.9625 5.6625 10.375 6.425 11.7875 5.8375C13.2 5.25 14.4375 3 16 3C17.5625 3 18.75 5.225 20.2125 5.8375C21.675 6.45 24.0375 5.6625 25.1875 6.8125C26.3375 7.9625 25.575 10.375 26.1625 11.7875C26.75 13.2 29 14.4375 29 16C29 17.5625 26.775 18.75 26.1625 20.2125C25.55 21.675 26.3375 24.0375 25.1875 25.1875C24.0375 26.3375 21.625 25.575 20.2125 26.1625C18.8 26.75 17.5625 29 16 29C14.4375 29 13.25 26.775 11.7875 26.1625C10.325 25.55 7.9625 26.3375 6.8125 25.1875Z" stroke="#FBB723" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M21.5 13L14.1625 20L10.5 16.5" stroke="#FBB723" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              </svg>
              <div className='namininfo'>
                <div className='nameofstu d-flex align-item-center'>
                  {notifi.fullName} Purchased Course - <div className='dateofpurchase'>03/14/2024</div>
                </div>
                <div className='infoabout'>
                  The student {notifi.fullName} purchased the {notifi.courseEnrollments[0].name} {notifi.courseEnrollments[0].language} course on {notifi.createdAt ? new Date(notifi.createdAt).toISOString().split('T')[0] : "Invalid Date"} at {notifi.createdAt ? new Date(notifi.createdAt).toISOString().split('T')[1].split('.')[0] : "Invalid Date"}
                </div>
              </div>
            </div>
            <Link to={`/studentinfo/${notifi._id}`}>
              <div className='detailerbtn'>
                See Detail
              </div>
            </Link>
          </div>
        ))
      }
    </div>
  )
}

export default Notifyer;
