import React, { useEffect, useState } from 'react'
import AdminNav from '../AdminDashboard/AdminNav'
import "./style.css"
import logo from "../Authenticate/Image/ELDT LOGO.svg"
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Dropdown, message, Space, Select, Modal } from 'antd';

import { fetchallstudents } from '../Services/AllFunctions';
import Searchbar from '../AdminDashboard/Searchbar';
import StudentTable from './StudentTable';
import Studentinfo from './Studentinfo';
import AddStudent from './AddStudent';
import { Link } from 'react-router-dom';
const { Option } = Select;

const items = [
    {
        label: 'Class A ',
        key: '1',

    },
    {
        label: 'Class B',
        key: '2',

    },
    {
        label: 'School Bus',
        key: '3',


    },
    {
        label: 'Hazmat',
        key: '4',


    },
    {
        label: 'Passenger',
        key: '5',


    },


];







function Student({ userRole }) {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [studentId, setStudentId] = useState("")
    const [showstu, setShowstu] = useState(true)
    const [addstudent, setAddstudent] = useState(false)
    const [student, setStudent] = useState(false)
   const [fillt, setFillt]=useState(false)
    const showaddstudent = () => {
        setShowstu(false)
        setAddstudent(true)
    }

    const handleMenuClick = (e) => {
        const { key } = e;
        const selectedItem = items.find(item => item.key === key);
        const selectedLabel = selectedItem.label;
        changefor(selectedLabel);
        message.info(`Selected: ${selectedLabel}`);
    };
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };
    const [beforefilter, setBeforeFilter] = useState([]); // State variable for storing data before filtering

    // Function to apply filter
    const changefor = (selectedValue) => {
        // If no filter is selected or "reset" is selected, reset to original data
        setFillt(false)
        if (!selectedValue || selectedValue === "reset") {
            setAllstudentinfo(beforefilter.length ? beforefilter : allstudentinfo);
            return;
        }
    
        // Store original data before filtering if not already stored
        if (beforefilter.length === 0) {
            setBeforeFilter(allstudentinfo);
        }
    
        // Use `beforefilter` or fallback to `allstudentinfo` for filtering
        const sourceData = beforefilter.length ? beforefilter : allstudentinfo;
    
        // Apply filter to the data
        const filteredData = sourceData.filter(student => 
            student.courseEnrollments[0]?.name === selectedValue // Handle undefined courseEnrollments gracefully
        );
    
        setAllstudentinfo(filteredData);
    };
    
    const showDrawer = () => {
        setOpen(true);
    };
    const showDrawer2 = () => {
        setOpen2(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onClose2 = () => {
        setOpen2(false);
    };
    const { RangePicker } = DatePicker;


    const onChange = (dates) => {
        if (dates && dates.length === 2) {
            const [startDate, endDate] = dates;
            const filteredData = allstudentinfo.filter(student => {
                const studentDate = new Date(student.createdAt);
                return studentDate >= startDate && studentDate <= endDate;
            });
            setAllstudentinfo(filteredData);
        }
    };
    const [allstudentinfo, setAllstudentinfo] = useState([])
    const [stored, setStored] = useState(allstudentinfo)

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchallstudents();
            setAllstudentinfo(response.data);
        };

        fetchData();
    }, []);
    const [loading, setLoading] = useState(false)
    const fetchDataing = async () => {
        setLoading(true)
        const response = await fetchallstudents();
        setAllstudentinfo(response.data);
        setLoading(false)

    };
    const [storedStudentInfo, setStoredStudentInfo] = useState(allstudentinfo)

    const handleChange = (selectedValue) => {
        // If no filter is selected, reset to original data
        setFillt(false)

        if (!selectedValue) {
            setAddstudent(storedStudentInfo.length ? storedStudentInfo : allstudentinfo);
            return;
        }
    
        // Store original data before filtering if not already stored
        if (storedStudentInfo.length === 0) {
            setStoredStudentInfo(allstudentinfo);
        }
    
        // Use `allstudentinfo` for filtering to ensure correct data is used
        const filteredData = (storedStudentInfo.length ? storedStudentInfo : allstudentinfo).filter(student => {
            const percent = student.courseEnrollments[0]?.percent || 0; // Handle undefined values gracefully
            switch (selectedValue) {
                case '0 - 25%':
                    return percent >= 0 && percent <= 25;
                case '26 - 50%':
                    return percent > 25 && percent <= 50;
                case '51 - 75%':
                    return percent > 50 && percent <= 75;
                case '76 - 100%':
                    return percent > 75 && percent <= 100;
                case 'completed':
                    return percent === 100;
                default:
                    return true;
            }
        });
    
        setAllstudentinfo(filteredData);
    };
    const filterAndSortStudents = (sortOrder) => {
        // If reset, restore original data
        setFillt(true)

        if (sortOrder === "reset") {
            setAllstudentinfo(storedStudentInfo.length ? storedStudentInfo : allstudentinfo);
            return;
        }
    
        // Validate sortOrder, allow only "asc" or "desc"
        if (!["asc", "desc"].includes(sortOrder)) {
            console.error("Invalid sortOrder. Please provide 'asc', 'desc', or 'reset'.");
            return;
        }
    
        // Filter students with percentage 100%
        const filteredData = allstudentinfo.filter(student => {
            const percent = student.courseEnrollments[0]?.percent;
            return percent === 100; // Only include students who have completed 100%
        });
    
        // Sort the filtered data by `dateoflast`
        const sortedData = filteredData.sort((a, b) => {
            const dateA = new Date(a.courseEnrollments[0]?.dateoflast);
            const dateB = new Date(b.courseEnrollments[0]?.dateoflast);
    
            // Ascending: Earlier dates first; Descending: Later dates first
            return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        });
    
        // Update the state with the filtered and sorted data
        setAllstudentinfo(sortedData);
    };
    
    
    
    const handlestatus = (selectedValue) => {
        setFillt(false)

        // If selectedValue is "reset" or no value is selected, reset to original data
        if (selectedValue === "reset" || !selectedValue) {
            setAllstudentinfo(beforefilter.length ? beforefilter : allstudentinfo);
            return;
        }
    
        // Store original data before filtering if not already stored
        if (beforefilter.length === 0) {
            setBeforeFilter(allstudentinfo);
        }
    
        // Use `beforefilter` or fallback to `allstudentinfo` for filtering
        const sourceData = beforefilter.length ? beforefilter : allstudentinfo;
    
        // Apply filter to the data
        const filteredData = sourceData.filter(student => {
            if (selectedValue === "Active") {
                return student.Status === true;
            } else if (selectedValue === "Inactive") {
                return student.Status === false;
            }
            return true; // Keep all students for other values
        });
    
        setAllstudentinfo(filteredData);
    };
    
console.log(allstudentinfo)
    const [selectoption, setSelectoption] = useState(false)
    
    return (
        <>
            {
                showstu ? (<>

                    <Drawer title="Filtter" onClose={onClose2} open={open2} placement={"bottom"} size={"large"} >
                        <div className=' filterssec gap-3 '>
                            <label htmlFor="">Start Date</label>
                            <div>

                                <Space direction="vertical">

                                    <DatePicker onChange={onChange} picker="week" placeholder="placeholder" className='datepicker' />

                                </Space>
                            </div>
                            <label htmlFor="">Course Type</label>
                            <div>

                                <Space wrap  >


                                    <Dropdown menu={menuProps} className='datepicker plaec'>
                                        <Button>
                                            <Space className='plaec'>
                                                placeholder
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>

                                </Space>
                            </div>
                            <label htmlFor="">Status</label>
                            <div>

                                <Space wrap >


                                    <Dropdown menu={menuProps} className='datepicker'>
                                        <Button>
                                            <Space className='plaec'>
                                                placeholder
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>

                                </Space>
                            </div>
                            <div>
                                <label htmlFor="">Percentage Of Complete Course</label>
                                <Space wrap >


                                    <Dropdown menu={menuProps} className='datepicker'>
                                        <Button>
                                            <Space className='plaec' >
                                                placeholder
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>

                                </Space>
                            </div>




                        </div>
                    </Drawer>


                    <div className="maincontainer">

                        <Searchbar />
                        <div className='mobile'>


                            <div className='' onClick={() => { setSelectoption(true) }}>
                                <div className='tablebtn'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M3.125 10H16.875" stroke="#2C292A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10 3.125V16.875" stroke="#2C292A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    Add Student
                                </div>

                            </div>
                            <div className='liststu '>
                                <div className='tableheading'>List of students</div>
                                <div className='tableheading2'>{allstudentinfo.length} <span className='inertext'>Students</span></div>
                                {/* <span className='tableheading '>List of students <span>359 <span className='inertext'>students</span></span></span> */}

                            </div>
                            <div className='tablebtn2 ' onClick={showDrawer2}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M4.44703 4.5H20.5533C20.6988 4.50005 20.8411 4.5424 20.9629 4.62191C21.0847 4.70141 21.1808 4.81463 21.2395 4.94777C21.2981 5.08092 21.3167 5.22824 21.2931 5.37179C21.2695 5.51533 21.2046 5.64891 21.1064 5.75625L14.947 12.5344C14.8188 12.6716 14.7483 12.8529 14.7502 13.0406V18.3469C14.7513 18.4715 14.721 18.5944 14.662 18.7042C14.603 18.814 14.5172 18.9072 14.4127 18.975L11.4127 20.9719C11.3001 21.046 11.1697 21.0884 11.0351 21.0947C10.9005 21.101 10.7667 21.0709 10.6477 21.0076C10.5288 20.9443 10.4291 20.8501 10.3591 20.7349C10.2892 20.6198 10.2515 20.4879 10.2502 20.3531V13.0406C10.252 12.8529 10.1815 12.6716 10.0533 12.5344L3.8939 5.75625C3.7957 5.64891 3.73084 5.51533 3.70722 5.37179C3.6836 5.22824 3.70223 5.08092 3.76086 4.94777C3.81948 4.81463 3.91556 4.70141 4.03739 4.62191C4.15922 4.5424 4.30155 4.50005 4.44703 4.5V4.5Z" stroke="#0A0A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Add Student
                            </div>
                            <div className='tablewraper'>


                                {
                                    allstudentinfo.map((student) => {
                                        return (
                                            <div className='inersteudentdetail' key={student._id}>

                                                <div>
                                                    <span className=''>Name :</span><span>{student.fullName}</span>
                                                </div>
                                                <div>
                                                    <span className=''>Email :</span><span>{student.Email}</span>
                                                </div>
                                                <div>
                                                    <span className=''>Start Date :</span><span>{student.createdAt ? new Date(student.createdAt).toISOString().split('T')[0] : "Invalid Date"}</span>

                                                </div>
                                                <div>
                                                    <span className=''>Course Type:</span><span>{student.courseEnrollments[0].name} ({student.courseEnrollments[0].language})</span>
                                                </div>
                                                <div>
                                                    <span className=''>Status</span><span>{student.courseEnrollments[0].completed}</span>
                                                </div>
                                                <div>
                                                    <span className=''>Completion % :</span><span><svg xmlns="http://www.w3.org/2000/svg" width="61" height="8" viewBox="0 0 61 8" fill="none">
                                                        <rect y="0.5" width="61" height="7" rx="3.5" fill="#D9D9D9" />
                                                        <rect y="0.5" width="53" height="7" rx="3.5" fill="#FFD880" />
                                                    </svg>  &nbsp; &nbsp;{student.courseEnrollments[0].percent}%</span>
                                                </div>
                                                <div className='tablebtn3'  onClick={() => {setShowstu(false); setStudent(true); setStudentId(student._id);}}>

                                                    Details
                                                </div>

                                            </div>
                                        )
                                    })
                                }



                            </div>


                        </div>
                        <div className=' filterssec gap-3 desktop flex-wrap'>
                            <div className='d-inline-grid '>
                                <label htmlFor="">Start Date</label>
                                <Space direction="vertical">

                                    <RangePicker onChange={onChange} className='datepicker' />

                                </Space>
                            </div>

                            <div className='d-inline-grid '>
                                <label htmlFor="">Course Type</label>
                                <Select defaultValue="Select Options" style={{ width: 254, height: 56 }} onChange={changefor}>
                                    <Option value="Class A">Class A</Option>
                                    <Option value="Class B">Class B</Option>
                                    <Option value="School Bus">School Bus</Option>
                                    <Option value="Hazmat">Hazmat</Option>
                                    <Option value="Passenger">Passenger</Option>
                                    <Option value="reset">Reset</Option>

                                </Select>
                            </div>

                            <div className='d-inline-grid '>
                                <label htmlFor="">Status</label>
                                <Select defaultValue="Active" style={{ width: 254, height: 56 }} onChange={handlestatus}>
                                    <Option value="Active">Active</Option>
                                    <Option value="Inactive">Inactive</Option>
                                    <Option value="reset">Reset</Option>

                                </Select>
                            </div>
                            <div className='d-inline-grid '>
                                <label htmlFor="">Percentage Of Complete Course</label>
                                <Select defaultValue="Please Select" style={{ width: 254, height: 56 }} onChange={handleChange}>
                                    <Option value="0 - 25%">0 - 25%</Option>
                                    <Option value="26 - 50%">26 - 50%</Option>
                                    <Option value="51 - 75%">51 - 75%</Option>
                                    <Option value="76 - 100%">76 - 100%</Option>
                                    <Option value="completed">Completed</Option>
                                    <Option value="reset">Reset</Option>

                                </Select>
                            </div>
                            <div className="d-inline-grid">
    <label htmlFor="sortSelect">Sort By Date Of Completion</label>
    <Select 
        id="sortSelect" 
        defaultValue="Please Select" 
        style={{ width: 254, height: 56 }} 
        onChange={filterAndSortStudents}
    >
        <Option value="asc">Ascending</Option>
        <Option value="desc">Descending</Option>
        <Option value="reset">Reset</Option>
    </Select>
</div>





                        </div>
                        <div className='mt-3' style={{ width: "100px", cursor: "pointer" }} onClick={fetchDataing}>
                            <div className='tablebtn'>
                                {
                                    loading ? ("Loading...") : ("Reset")
                                }
                            </div>

                        </div>
                        <div className="table-wraper pt-4 desktop" >
                            <div className='d-flex bg-white justify-content-between align-items-center p-3 '>
                                <div>
                                    <span className='tableheading '>List Of Students <span>{allstudentinfo?.length} <span className='inertext'>Students</span></span></span>

                                </div>

                                <div onClick={() => { setSelectoption(true) }}>
                                    <span className='tablebtn'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M3.125 10H16.875" stroke="#2C292A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M10 3.125V16.875" stroke="#2C292A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        Add Student
                                    </span>

                                </div>

                            </div>

                            <StudentTable fillt={fillt} fetchData={fetchDataing} allstudentinfo={allstudentinfo} setStudentId={setStudentId} setShowstu={setShowstu} setStudent={setStudent} />
                        </div>
                    </div>
                </>) : (null)
            }
            {
                student ? (<Studentinfo userRole={userRole} studentId={studentId} setShowstu={setShowstu} setStudent={setStudent} />) : (null)
            }
            {
                addstudent ? (<AddStudent />) : (null)
            }
            <Modal
                open={selectoption}
                onCancel={() => { setSelectoption(false) }}
                footer={null}
                closeIcon={null}
            >
                <div className='enrollmentpage'>
                    <div >
                        <div className='enrollment-heading'>Choose Your Enrollment Option</div>
                        <div className='buttonoption'>
                            <Link to="/courses"><button className='btnforenroll'>Enroll With Payment</button></Link>
                            <Link to="/addstudent">
                                <button className='btnforenroll'>Enroll For Free</button></Link>
                        </div>

                    </div>

                </div>

            </Modal>
        </>
    )
}

export default Student