import React, { useRef, useState } from 'react'
import "./style.css";
import CourseSelection from './CourseSelection';
import Paymentpage from './Paymentpage';
import { poststudent, poststudentbypayment } from '../Services/AllFunctions';
import { notification } from "antd";
import { Link, useNavigate } from 'react-router-dom';
function AddStudent() {

  const [personalinfo, setPersonalinfo] = useState(true)
  const [selectcourse, setSelectcourse] = useState(false)
  const [payment, setPayment] = useState(false)
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [zip, setZip] = useState("")
  const [courseName, setCourseName] = useState('');
  const [language, setLanguage] = useState('');
  const [courseId, setCourseId] = useState("")
  const [cardNumber, setCardNumber] = useState("");
  const [cardCode, setCardCode] = useState("");
  const [dateOfExpiry, setDateOfExpiry] = useState("");
 const [price,setPrice]=useState("50")

  const openErrorNotification = () => {
    notification.error({
      message: "Error",
      description: "Please fill all the blanks.",
    });
  };
  
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  
  const handelcourse = () => {
    // Check if any of the input fields are empty
    if (name === "" || address === "" || email === "" || zip === "") {
      // Fire error notification if any field is empty
      openErrorNotification();
    } else if (!validateEmail(email)) {
      // Check if email is in a valid format
      // Fire error notification for invalid email
      notification.error({
        message: "Error",
        description: "Please enter a valid email address.",
      });
    } else {
      // Proceed with setting selectCourse to true if all fields are filled and email is valid
      setPersonalinfo(false);
      setSelectcourse(true);
    }
  };
  
  const handelpayment = () => {

    setSelectcourse(false)
    setPayment(true)
  }

  const formdata = {
    fullName: name,
    Email: email,
    price: "free",
    address,
    zip,
    courseEnrollments: [
      {
        courseId: courseId,
        lessonIndex: 0,
        language: language,
        name: courseName
      }
    ]

  }
  const paymentdata = {
    fullName: name,
    Email: email,
    price,
    address,
    zip,
    courseEnrollments: [
      {
        courseId: courseId,
        lessonIndex: 0,
        language: language,
        name: courseName
      }
    ],
    cardNumber,
    cardCode,
    date: dateOfExpiry
  }
  const navigate =useNavigate()
  const addstudent = async() => {
    const res = await poststudent(formdata)
    console.log(res)

    if(res.success === true){
      openNotification("top")
      navigate("/students")
    }else{
      errornotify()
    }

  }
  const studentbypayment = async() => {

    const res =await poststudentbypayment(paymentdata)
    if(res.success === true){
          openNotification("top")
    }
    errornotify()
  }
  const openNotification = () => {
    notification.success({
      message: `Enrollment Alert`,
      description:
        `${name} have been successfully enrolled in ${courseName} ${language}`,
    });
  };
  const errornotify = () => {
    notification.error({
      message: `Enrollment Alert`,
      description:
        `Something Went Wrong`,
    });
  };
  return (
    <div className='allst' style={{ width: "100%" }}>
      <div className='addstudentback desktop' style={{cursor:"pointer"}}>
    <Link to="/students">  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path d="M11.959 6.85449L2.33398 6.85449" stroke="#696969" stroke-width="1.575" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6.27149 2.91699L2.33398 6.85449L6.27148 10.792" stroke="#696969" stroke-width="1.575" stroke-linecap="round" stroke-linejoin="round" />
        </svg> Back</Link>  
      </div>
      <div className='coverofaddition' style={{cursor:"pointer"}}>
        <div className='addstudentback mobileaddstudent'>
        <Link to="/students">      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M11.959 6.85449L2.33398 6.85449" stroke="#696969" stroke-width="1.575" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.27149 2.91699L2.33398 6.85449L6.27148 10.792" stroke="#696969" stroke-width="1.575" stroke-linecap="round" stroke-linejoin="round" />
          </svg> Back</Link>
        </div>
        <div className='titleofstudent'>
          Add New Student
        </div>


        {
          personalinfo ? (<>
            <div className='addmission-position dektop'>
              <div className='pagelocationdone'>
                Student Info
              </div>
              <div className='dots'>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <circle cx="5" cy="5" r="5" fill="#FFD87F" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <circle cx="5" cy="5" r="5" fill="#FFD87F" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <circle cx="5" cy="5" r="5" fill="#FFD87F" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <circle cx="5" cy="5" r="5" fill="#FFD87F" />
                </svg>
              </div>
              <div className='pagelocation' onClick={handelcourse}>
                Course
              </div>
            
            </div>
            <div className='addmission-position mobileaddstudent'>
              <div className='pagelocationdone'>
                Student info
              </div>
              <div className='dots'>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <circle cx="5" cy="5" r="5" fill="#FFD87F" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                  <circle cx="5" cy="5" r="5" fill="#FFD87F" />
                </svg>

              </div>
              <div className='pagelocation' onClick={handelcourse}>

              </div>
             
            </div>
            <div className='studentdata'>

              <div className='innerdivdatawhite' style={{ background: "#F9F9FC" }}>
                <div className='for_name'>
                  <span className='name_title'>Name:</span> 
                  &nbsp;<input className='name_student' style={{ background: "#F9F9FC" }} value={name} onChange={(e) => { setName(e.target.value) }} type='text' />
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div className='innerdivdata' >
                <div className='for_name'>
                  <span className='name_title'>Email:</span>
                  &nbsp;<input className='name_student' value={email} onChange={(e) => { setEmail(e.target.value) }} type='text' />

                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>


              <div className='innerdivdata' style={{ background: "#F9F9FC" }}>
                <div className='for_name'>
                  <span className='name_title'>Address:</span>
                  &nbsp;<input className='name_student' value={address} onChange={(e) => { setAddress(e.target.value) }} type='text' />

                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <div className='innerdivdata' >
                <div className='for_name'>
                  <span className='name_title'>Zip Code:</span>
                   &nbsp;<input className='name_student' value={zip} onChange={(e) => { setZip(e.target.value) }} type='text' />

                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>

            </div>
            <div className='addstudentbtn'>
              <Link to="/students">
              <span>Cancel</span>
              </Link>
              <span className='secondbtn' onClick={handelcourse} >Continue</span>
            </div>
          </>) : (null)
        }
        {
          selectcourse ? (<CourseSelection setSelectcourse={setSelectcourse} personalinfo={setPersonalinfo} language={language} setCourseName={setCourseName} setLanguage={setLanguage} setCourseId={setCourseId} courseName={courseName} addstudent={addstudent} handelpayment={handelpayment} />) : (null)
        }
       



      </div>

    </div>

  )
}

export default AddStudent