import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Route, redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("userId");
    if (token) {
      const userinfo = jwtDecode(token);
      setUser(userinfo);
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Route {...rest} render={(props) => (
      isLoggedIn && allowedRoles.includes(user?.role) ? (
        <Component {...props} />
      ) : (
        <redirect to="/login" />
      )
    )} />
  );
};

export default PrivateRoute;
