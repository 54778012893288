import React from 'react'
import pic from '../../notfound.png'
import { Link } from 'react-router-dom'
function NotFound() {
  return (
<>

<div className='d-flex align-items-center flex-column mx-auto '>

<img src={pic} alt="" width="100%"/>
<Link to="/">

<button className='btn btn-warning'> Back to home</button>
</Link>
</div>

</>
  )
}

export default NotFound