import React, { useEffect, useState } from 'react'
import studentimage from "./icons8-user-100.png";
import { Skeleton, Modal } from 'antd';
import Select from 'react-select';
import { getresults, studentDetail, updatestudent } from '../Services/AllFunctions';
import axios from 'axios';
import { SERVER_URL } from '../Constant/Constant';
function Studentinfo({ studentId, setShowstu, setStudent ,userRole }) {
    console.log(userRole)
    const [activebtn, setActivebtn] = useState(1)
    const [studentinformation, setStudentinformation] = useState(true)
    const [progressinfo, setProgressinfo] = useState(false)
    const [cardinfo, setCardinfo] = useState(false)
    const [allresults, setAllresults] = useState("")
    const [activecourse, setActivecourse] = useState(false)
    const [permit, setPermit] = useState(false)
    const [lesson, setLesson] = useState(0)
    const changeactivecourse = (index, num) => {
        setActivecourse(index)
        setLesson(num)
        fetchresults()
    }
    const changeactivebtn = (index) => {
        setActivebtn(index)

    }
    const [studentinfo, setStudentinfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const formdata = {
        studentId,
        indexNumber: lesson
    }
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Set loading state to true before fetching data
            try {
                const results = await (getresults(formdata,))
                setAllresults(results.data)
                const response = await studentDetail(studentId);
                if (response.success) {
                    setStudentinfo(response.data);
                    setName(response.data?.fullName)
                    setAddress(response.data?.address)
                    setState(response.data?.state)
                    setDateofbirth(response.data?.dateOfBirth)
                    setEmail(response.data?.Email)
                    setPassword(response.data?.password)
                    setEmployeeId(response.data?._id)
                } else {
                    console.error('Failed to fetch student:', response.data);
                }
            } catch (error) {
                console.error('Error fetching student:', error);
            } finally {
                setLoading(false); // Set loading state to false after fetching data
            }
        };

        fetchData();
    }, []);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const fetchresults = async () => {
        setLoading(true); // Set loading state to true before fetching data
        try {
            const results = await (getresults(formdata,))
            setAllresults(results.data)

            if (results.success) {
                setAllresults(results.data);
                console.log(studentinfo)
            } else {
                console.error('Failed to fetch student:', results.data);
            }
        } catch (error) {
            console.error('Error fetching student:', error);
        } finally {
            setLoading(false); // Set loading state to false after fetching data
        }
    };

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [dateofbirth, setDateofbirth] = useState("")
    const [address, setAddress] = useState("")
    const [password, setPassword] = useState("")
    const [state, setState] = useState("")
    const [phoneNumber, setPhoneNumber] = useState('');
    const [Emergencycontact, Setemergencycontact] = useState('');
    const handlePhoneNumberChange = (event) => {
        const input = event.target.value;

        // Remove non-numeric characters from input
        const numericInput = input.replace(/\D/g, '');

        // Apply phone number formatting
        const formattedPhoneNumber = formatPhoneNumber(numericInput);

        // Limit the input to 10 characters
        const limitedInput = formattedPhoneNumber.slice(0, 14);

        // Update the state
        setPhoneNumber(limitedInput);
    };
    const handleEmergencycontact = (event) => {
        const input = event.target.value;

        // Remove non-numeric characters from input
        const numericInput = input.replace(/\D/g, '');

        // Apply phone number formatting
        const formattedPhoneNumber = formatPhoneNumber(numericInput);

        // Limit the input to 10 characters
        const limitedInput = formattedPhoneNumber.slice(0, 14);

        // Update the state
        Setemergencycontact(limitedInput);
    };
    const formatPhoneNumber = (phoneNumber) => {
        // Apply the desired format (XXX)-XXX-XXXX
        const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]})-${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    };
    const showModal = (content, name) => {
        // setModalContent(content, name)
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const formdata2 = {
        fullName: name,
        Email: email,
        dateOfBirth: dateofbirth,
        address,
        password: password,
        phone: phoneNumber,
        state
    }
    const [employeeid, setEmployeeId] = useState("")
    const handleUpdate = async () => {
        const response = await updatestudent(formdata2, employeeid);
        // Handle response
    };

    const stateOptions = [
        { value: 'AL', label: 'Alabama' },
        { value: 'AK', label: 'Alaska' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'DE', label: 'Delaware' },
        { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' },
        { value: 'HI', label: 'Hawaii' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'NY', label: 'New York' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' },
    ];
    const handleStateChange = (selectedOption) => {
        setState(selectedOption.label);
    };
    const [feeInfo, setFeeInfo] = useState(null);
  
    const fetchFeeInfo = async () => {
      setLoading(true);
      setStudentinformation(false); 
      setCardinfo(true); 
      setProgressinfo(false) 
      try {
        const response = await axios.get(`${SERVER_URL}/api/fee/${studentinfo._id}`);
        setFeeInfo(response.data);
      } catch (error) {
        console.log('Error fetching fee information');
      } finally {
        setLoading(false);
      }
    };
    const downloadImage = (imageUrl) => {
        // Make a request to fetch the image data
        axios({
            url: imageUrl,
            method: 'GET',
            responseType: 'blob', // This is important to handle binary data
        }).then((response) => {
            // Create a blob URL for the image data
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'image.jpg'); // Set the desired filename here
            // Append the link to the body and trigger the download
            document.body.appendChild(link);
            link.click();
            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(url);
        }).catch((error) => {
            console.error('Error downloading image:', error);
        });
    };
    return (
        <>
            <Modal open={isModalOpen} onOk={handleOk} footer={false} title={null} closeIcon={null} className='mainheadermodal'>

                <div>
                    <div className="headermodal">
                        <div>Update Student</div>
                        <div>
                            <svg onClick={handleCancel} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M24.666 6.66699L6.66602 24.667" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M24.666 24.667L6.66602 6.66699" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                    </div>
                    <div className='headermodalbody'>
                        <div className='modalcontent2'>Name</div>
                        <input type="text" className='form-control' value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Enter Name' />
                        <div className='modalcontent2'>Email</div>
                        <input type="text" className='form-control' value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='Enter Email' />
                        <div className='modalcontent2'>Phone Number</div>
                        <input type="text" className='form-control' value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            maxLength={14} placeholder='Enter Phone Number' />


                        <div className='modalcontent2'>Date of Birth</div>
                        <input type="date" className='form-control' value={dateofbirth} onChange={(e) => { setDateofbirth(e.target.value) }} placeholder='Date of Bith' />
                        <div className='modalcontent2'>Address</div>
                        <input type="text" className='form-control' value={address} onChange={(e) => { setAddress(e.target.value) }} placeholder='Enter Address' />
                        <div className='modalcontent2'>State</div>
                        <Select
                            options={stateOptions}
                            value={stateOptions.find((option) => option.value === state)} // Ensure correct display of selected state
                            onChange={handleStateChange}
                            placeholder="Select State"
                            className='form-control'
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    border: 'none',
                                    width: '100%',
                                    outline: 'none', // Remove blue border on focus
                                    boxShadow: 'none', // Remove box shadow on focus
                                }),
                            }}
                        />
                        <div className='modalcontent2'>Password</div>
                        <input type="text" className='form-control' value={password} onChange={(e) => { setPassword(e.target.value) }} placeholder='Enter Password' />

                        <div className='addstudentbtn'>
                            <span onClick={handleCancel}>Cancel</span><span className='secondbtn' onClick={()=>{handleCancel();handleUpdate()}} >Save</span>
                        </div>
                    </div>

                </div>



            </Modal>
            <div>
                <div className='goback' onClick={() => { setShowstu(true); setStudent(false) }} style={{ cursor: "pointer" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M11.959 6.85449L2.33398 6.85449" stroke="#696969" stroke-width="1.575" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6.27149 2.91699L2.33398 6.85449L6.27148 10.792" stroke="#696969" stroke-width="1.575" stroke-linecap="round" stroke-linejoin="round" />
                    </svg> Back
                </div>  
            </div>
          
            <div className='studentinfo'>

               
                <div className='main_student_nav'>

                    <div className='studentimagesection'>

                        <div className='imageofstudent'>
                            {/* <div className='goback1'>
                                 Back
                            </div> */}
                            <div>
                              
                            </div>
                            {
                                loading ?("Loading ...."):( <img src={studentinfo.image || studentimage} alt='image of student' />)
                            }
                           

                            

                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="13.5" cy="13.5" r="13" fill="white" stroke="#F1F1F1" />
                                <path d="M13 16H11V14L17 8L19 10L13 16Z" stroke="#0A0A0A" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.5 9.5L17.5 11.5" stroke="#0A0A0A" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18.5 13.5V19C18.5 19.1326 18.4473 19.2598 18.3536 19.3536C18.2598 19.4473 18.1326 19.5 18 19.5H8C7.86739 19.5 7.74021 19.4473 7.64645 19.3536C7.55268 19.2598 7.5 19.1326 7.5 19V9C7.5 8.86739 7.55268 8.74021 7.64645 8.64645C7.74021 8.55268 7.86739 8.5 8 8.5H13.5" stroke="#0A0A0A" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>
                        <span className='name_of_student'>{studentinfo?.fullName} </span>
                        <span className='mail_of_student'>{studentinfo?.Email} </span>
                    </div>
                    <div className='student_progress_bar'>
                        <div className='student_bar' style={{ width: `${studentinfo?.courseEnrollments[0]?.percent}%` }}></div>
                        <div className='progress_percentage'>{studentinfo?.courseEnrollments[0]?.percent.toFixed(2)}%</div>
                    </div>



                    <div className='Student_Nav_btn desktopnav'>
                        <div
                            className={`student_profile ${activebtn === 1 ? "studentactive" : ""
                                }`
                            } onClick={() => { changeactivebtn(1); setStudentinformation(true); setCardinfo(false); setProgressinfo(false) ;setPermit(false)}}                 >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M11.875 16.2498V12.4998C11.875 12.334 11.8092 12.1751 11.6919 12.0579C11.5747 11.9406 11.4158 11.8748 11.25 11.8748H8.75C8.58424 11.8748 8.42527 11.9406 8.30806 12.0579C8.19085 12.1751 8.125 12.334 8.125 12.4998V16.2498C8.125 16.4156 8.05915 16.5745 7.94194 16.6917C7.82473 16.809 7.66576 16.8748 7.5 16.8748H3.75C3.58424 16.8748 3.42527 16.809 3.30806 16.6917C3.19085 16.5745 3.125 16.4156 3.125 16.2498V9.02324C3.1264 8.93674 3.14509 8.8514 3.17998 8.77224C3.21486 8.69308 3.26523 8.6217 3.32812 8.5623L9.57812 2.88261C9.69334 2.77721 9.84384 2.71875 10 2.71875C10.1562 2.71875 10.3067 2.77721 10.4219 2.88261L16.6719 8.5623C16.7348 8.6217 16.7851 8.69308 16.82 8.77224C16.8549 8.8514 16.8736 8.93674 16.875 9.02324V16.2498C16.875 16.4156 16.8092 16.5745 16.6919 16.6917C16.5747 16.809 16.4158 16.8748 16.25 16.8748H12.5C12.3342 16.8748 12.1753 16.809 12.0581 16.6917C11.9408 16.5745 11.875 16.4156 11.875 16.2498Z" stroke="#0A0A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> Student Profile
                        </div>
                        <div className={`student_profile ${activebtn === 2 ? "studentactive" : ""
                            }`
                        } onClick={() => { changeactivebtn(2); setStudentinformation(false); setCardinfo(false); setProgressinfo(true); fetchresults();setPermit(false) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M2.5 5V11.25" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.23438 16.8747C4.85974 15.9155 5.71451 15.1275 6.72128 14.5821C7.72804 14.0366 8.85497 13.751 10 13.751C11.145 13.751 12.272 14.0366 13.2787 14.5821C14.2855 15.1275 15.1403 15.9155 15.7656 16.8747" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.5 5L10 7.5L2.5 5L10 2.5L17.5 5Z" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.2266 6.42188C13.7999 7.04795 14.1787 7.8273 14.3167 8.66492C14.4548 9.50253 14.3462 10.3622 14.0042 11.1392C13.6622 11.9162 13.1015 12.5768 12.3905 13.0407C11.6795 13.5045 10.8489 13.7515 10 13.7515C9.15108 13.7515 8.3205 13.5045 7.60951 13.0407C6.89852 12.5768 6.33783 11.9162 5.99581 11.1392C5.65378 10.3622 5.54518 9.50253 5.68326 8.66492C5.82134 7.8273 6.20012 7.04795 6.77344 6.42188" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> Course Completion
                        </div>
                        {userRole === 'Instructor' || userRole === 'Coordinator' ? null : (
        <div
          className={`student_profile ${activebtn === 3 ? 'studentactive' : ''}`}
          onClick={() => {
            changeactivebtn(3);
            fetchFeeInfo();
            ;setPermit(false)
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10 1.875V18.125" stroke="#696969" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.375 6.875C14.375 6.46462 14.2942 6.05826 14.1371 5.67911C13.9801 5.29997 13.7499 4.95547 13.4597 4.66529C13.1695 4.37511 12.825 4.14492 12.4459 3.98788C12.0667 3.83083 11.6604 3.75 11.25 3.75H8.4375C7.6087 3.75 6.81384 4.07924 6.22779 4.66529C5.64174 5.25134 5.3125 6.0462 5.3125 6.875C5.3125 7.7038 5.64174 8.49866 6.22779 9.08471C6.81384 9.67076 7.6087 10 8.4375 10H11.875C12.7038 10 13.4987 10.3292 14.0847 10.9153C14.6708 11.5013 15 12.2962 15 13.125C15 13.9538 14.6708 14.7487 14.0847 15.3347C13.4987 15.9208 12.7038 16.25 11.875 16.25H8.125C7.2962 16.25 6.50134 15.9208 5.91529 15.3347C5.32924 14.7487 5 13.9538 5 13.125" stroke="#696969" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg> Payment
        </div>
      )}
               <div className={`student_profile ${activebtn === 4 ? "studentactive" : ""
                            }`
                        } onClick={() => { changeactivebtn(4);setStudentinformation(false);setCardinfo(false);setProgressinfo(false);setPermit(true) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 1.875V18.125" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.375 6.875C14.375 6.46462 14.2942 6.05826 14.1371 5.67911C13.9801 5.29997 13.7499 4.95547 13.4597 4.66529C13.1695 4.37511 12.825 4.14492 12.4459 3.98788C12.0667 3.83083 11.6604 3.75 11.25 3.75H8.4375C7.6087 3.75 6.81384 4.07924 6.22779 4.66529C5.64174 5.25134 5.3125 6.0462 5.3125 6.875C5.3125 7.7038 5.64174 8.49866 6.22779 9.08471C6.81384 9.67076 7.6087 10 8.4375 10H11.875C12.7038 10 13.4987 10.3292 14.0847 10.9153C14.6708 11.5013 15 12.2962 15 13.125C15 13.9538 14.6708 14.7487 14.0847 15.3347C13.4987 15.9208 12.7038 16.25 11.875 16.25H8.125C7.2962 16.25 6.50134 15.9208 5.91529 15.3347C5.32924 14.7487 5 13.9538 5 13.125" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> Permit Image
                        </div>
                    </div>
                    <div className='Student_Nav_btn mobilenav'>
                        <div
                            className={`student_profile ${activebtn === 1 ? "studentactive" : ""
                                }`
                            } onClick={() => { changeactivebtn(1) }}                 >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M11.875 16.2498V12.4998C11.875 12.334 11.8092 12.1751 11.6919 12.0579C11.5747 11.9406 11.4158 11.8748 11.25 11.8748H8.75C8.58424 11.8748 8.42527 11.9406 8.30806 12.0579C8.19085 12.1751 8.125 12.334 8.125 12.4998V16.2498C8.125 16.4156 8.05915 16.5745 7.94194 16.6917C7.82473 16.809 7.66576 16.8748 7.5 16.8748H3.75C3.58424 16.8748 3.42527 16.809 3.30806 16.6917C3.19085 16.5745 3.125 16.4156 3.125 16.2498V9.02324C3.1264 8.93674 3.14509 8.8514 3.17998 8.77224C3.21486 8.69308 3.26523 8.6217 3.32812 8.5623L9.57812 2.88261C9.69334 2.77721 9.84384 2.71875 10 2.71875C10.1562 2.71875 10.3067 2.77721 10.4219 2.88261L16.6719 8.5623C16.7348 8.6217 16.7851 8.69308 16.82 8.77224C16.8549 8.8514 16.8736 8.93674 16.875 9.02324V16.2498C16.875 16.4156 16.8092 16.5745 16.6919 16.6917C16.5747 16.809 16.4158 16.8748 16.25 16.8748H12.5C12.3342 16.8748 12.1753 16.809 12.0581 16.6917C11.9408 16.5745 11.875 16.4156 11.875 16.2498Z" stroke="#0A0A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className={`student_profile ${activebtn === 2 ? "studentactive" : ""
                            }`
                        } onClick={() => { changeactivebtn(2); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M2.5 5V11.25" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.23438 16.8747C4.85974 15.9155 5.71451 15.1275 6.72128 14.5821C7.72804 14.0366 8.85497 13.751 10 13.751C11.145 13.751 12.272 14.0366 13.2787 14.5821C14.2855 15.1275 15.1403 15.9155 15.7656 16.8747" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.5 5L10 7.5L2.5 5L10 2.5L17.5 5Z" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.2266 6.42188C13.7999 7.04795 14.1787 7.8273 14.3167 8.66492C14.4548 9.50253 14.3462 10.3622 14.0042 11.1392C13.6622 11.9162 13.1015 12.5768 12.3905 13.0407C11.6795 13.5045 10.8489 13.7515 10 13.7515C9.15108 13.7515 8.3205 13.5045 7.60951 13.0407C6.89852 12.5768 6.33783 11.9162 5.99581 11.1392C5.65378 10.3622 5.54518 9.50253 5.68326 8.66492C5.82134 7.8273 6.20012 7.04795 6.77344 6.42188" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        {
                            userRole === "Instructor" || "Coordinator"?(null):(
                        <div className={`student_profile ${activebtn === 3 ? "studentactive" : ""
                            }`
                        } onClick={() => { changeactivebtn(3);fetchFeeInfo() }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 1.875V18.125" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14.375 6.875C14.375 6.46462 14.2942 6.05826 14.1371 5.67911C13.9801 5.29997 13.7499 4.95547 13.4597 4.66529C13.1695 4.37511 12.825 4.14492 12.4459 3.98788C12.0667 3.83083 11.6604 3.75 11.25 3.75H8.4375C7.6087 3.75 6.81384 4.07924 6.22779 4.66529C5.64174 5.25134 5.3125 6.0462 5.3125 6.875C5.3125 7.7038 5.64174 8.49866 6.22779 9.08471C6.81384 9.67076 7.6087 10 8.4375 10H11.875C12.7038 10 13.4987 10.3292 14.0847 10.9153C14.6708 11.5013 15 12.2962 15 13.125C15 13.9538 14.6708 14.7487 14.0847 15.3347C13.4987 15.9208 12.7038 16.25 11.875 16.25H8.125C7.2962 16.25 6.50134 15.9208 5.91529 15.3347C5.32924 14.7487 5 13.9538 5 13.125" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>)}
                    </div>
                    {studentinformation ? (<>
                        {
                            loading ? (
                                <Skeleton />


                            ) : (<div className='studentdata'>
                                <div className='innerdivdata'>
                                    <div className='for_name'>
                                        <span className='name_title'>Name:</span>
                                        <span className='name_student'> {studentinfo?.fullName}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.fullName)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>

                                <div className='innerdivdatawhite'>
                                    <div className='for_name'>
                                        <span className='name_title'>Email:</span>
                                        <span className='name_student'> {studentinfo?.Email}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.Email)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div className='innerdivdata'>
                                    <div className='for_name'>
                                        <span className='name_title'>Date of Birth:</span>
                                        <span className='name_student'> {studentinfo?.dateOfBirth}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.dateOfBirth)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>

                                <div className='innerdivdatawhite'>
                                    <div className='for_name'>
                                        <span className='name_title'>Address:</span>
                                        <span className='name_student'> {studentinfo?.address}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.address)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div className='innerdivdata'>
                                    <div className='for_name'>
                                        <span className='name_title'>Phone:</span>
                                        <span className='name_student'> {studentinfo?.phone}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal()} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div className='innerdivdatawhite'>
                                    <div className='for_name'>
                                        <span className='name_title'>License State:</span>
                                        <span className='name_student'>  {studentinfo?.state}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.state)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div className='innerdivdata'>
                                    <div className='for_name'>
                                        <span className='name_title'>License Number:</span>
                                        <span className='name_student'> {studentinfo?.licenseNumber}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.licenseNumber)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div className='innerdivdatawhite'>
                                    <div className='for_name'>
                                        <span className='name_title'>Password:</span>
                                        <span className='name_student'> {studentinfo?.password}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.password)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>)

                        }
                    </>) : (<></>)}

                    {
                        progressinfo ? (<>
                            <div className='Student_Nav_btn desktopnav'>
                                {studentinfo.courseEnrollments.map((student, index) => {
                                    return (
                                        <>
                                            <div className={`student_profile ${activecourse === index + 1 ? "studentactive" : ""
                                                }`
                                            } onClick={() => { changeactivecourse(index + 1, index) }}>
                                                {student.name}
                                            </div>
                                        </>
                                    )
                                })

                                }


                            </div>
                            <div className='progressinfo'>
                                <div className='single-progress'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M2.5 5V11.25" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M4.23438 16.8747C4.85974 15.9155 5.71451 15.1275 6.72128 14.5821C7.72804 14.0366 8.85497 13.751 10 13.751C11.145 13.751 12.272 14.0366 13.2787 14.5821C14.2855 15.1275 15.1403 15.9155 15.7656 16.8747" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M17.5 5L10 7.5L2.5 5L10 2.5L17.5 5Z" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.2266 6.42188C13.7999 7.04795 14.1787 7.8273 14.3167 8.66492C14.4548 9.50253 14.3462 10.3622 14.0042 11.1392C13.6622 11.9162 13.1015 12.5768 12.3905 13.0407C11.6795 13.5045 10.8489 13.7515 10 13.7515C9.15108 13.7515 8.3205 13.5045 7.60951 13.0407C6.89852 12.5768 6.33783 11.9162 5.99581 11.1392C5.65378 10.3622 5.54518 9.50253 5.68326 8.66492C5.82134 7.8273 6.20012 7.04795 6.77344 6.42188" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <div className='progressnumber'>
                                        <div className='progressdigit'>
                                            {studentinfo.courseEnrollments[0].percent.toFixed(2)}%
                                        </div>
                                        <div className='definetext'>
                                            Course completed
                                        </div>
                                    </div>
                                </div>
                                <div className='single-progress'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M13.125 17.5L8.75 14.375L4.375 17.5V5.625C4.375 5.45924 4.44085 5.30027 4.55806 5.18306C4.67527 5.06585 4.83424 5 5 5H12.5C12.6658 5 12.8247 5.06585 12.9419 5.18306C13.0592 5.30027 13.125 5.45924 13.125 5.625V17.5Z" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6.875 5V3.125C6.875 2.95924 6.94085 2.80027 7.05806 2.68306C7.17527 2.56585 7.33424 2.5 7.5 2.5H15C15.1658 2.5 15.3247 2.56585 15.4419 2.68306C15.5592 2.80027 15.625 2.95924 15.625 3.125V15L13.125 13.2109" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <div className='progressnumber'>
                                        <div className='progressdigit'>
                                            {studentinfo.courseEnrollments[0].lessonIndex}/35
                                        </div>
                                        <div className='definetext'>
                                            Sections completed    </div>
                                    </div>
                                </div>
                                <div className='single-progress'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M13.4375 8.125L8.85156 12.5L6.5625 10.3125" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <div className='progressnumber'>
                                        <div className='progressdigit'>
                                            {allresults?.averagePercentage.toFixed(2)}
                                        </div>
                                        <div className='definetext'>
                                            Average quiz percentage    </div>
                                    </div>
                                </div>

                            </div>
                            <div className='studentdata'>
                                {
                                    allresults.results.map((result, index) => {
                                        return (
                                            <div className={`innerdivdata ${index % 2 === 0 ? 'stripe-grey' : 'stripe-white'}`}>
                                                <div className='for_name'>
                                                    <span className='name_title'>Moudels:</span>
                                                    <span className='name_student'>{index + 1}</span>
                                                </div>
                                                <div className='lessonprogress'>
                                                    <div className='d-flex gap-2 align-items-center'>
                                                        <div className='progresslesson'>
                                                            <div className='realprogress' style={{ width: `${result.percentage}%` }}></div>
                                                        </div>
                                                        <div className='numberofprogress'>{result.percentage.toFixed(2)}%</div>
                                                    </div>
                                                    <div className='forborder'></div>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <div className='numberofprogress'>Lesson:</div>
                                                        <div className='numberprogresslesson'>4/4</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>




                        </>) : (<></>)
                    }
                    {
                        cardinfo ? (<>
                        {
                            loading ? (<Skeleton/>):(
                                  <div className='studentdata'>


                                <div className='innerdivdata'>
                                    <div className='for_name'>
                                        <span className='name_title'>Card Number:</span>
                                        <span className='name_student'> {feeInfo?.cardNumber}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div className='innerdivdatawhite'>
                                    <div className='for_name'>
                                        <span className='name_title'>CVV:</span>
                                        <span className='name_student'> {feeInfo?.cvv}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div className='innerdivdata'>
                                    <div className='for_name'>
                                        <span className='name_title'>Date of Expiry</span>
                                        <span className='name_student'> {feeInfo?.dateof}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>



                            </div>
                            )
                        }
                          


                        </>) : (<></>)
                    }
{
    permit ? (
        <>
            {
                loading ? (
                    <Skeleton />
                ) : (
                    studentinfo?.pemritimage ? (
                        <div className='studentdata'>
                            <img src={studentinfo.pemritimage} className='mx-auto' alt='permitimage' width="300" height="300" />
                            {/* Add a button to trigger the download */}
                            <button className='btn btn-warning' onClick={() => downloadImage(studentinfo.pemritimage)}>Download Image</button>
                        </div>
                    ) : (
                        <div className='studentdata'>
                            <div className="alert alert-danger" role="alert">
                            Image not available
                        </div>
                        </div>
                        
                    )
                )
            }
        </>
    ) : null
}


                </div>
                <div className='diffrencer'></div>
            </div>
        </>

    )
}

export default Studentinfo