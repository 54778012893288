import React, { useEffect, useRef, useState } from 'react'
import { SERVER_URL } from '../Constant/Constant';
import axios from 'axios';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';

function Searchbar({renderPage}) {
    const [isFocused, setIsFocused] = useState(false);
    const [nameofstudents, setNameofstudents] = useState([]);
    const suggestionsRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
          setIsFocused(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    const allname = async (name) => {
        try {
          const response = await axios.get(`${SERVER_URL}/api/search?q=${name}`);
          setNameofstudents(response.data.students)
        } catch (error) {
          console.log(error?.response?.data);
          return { success: false, data: error?.response?.data };
        }
      }
    const handleFocus = () => {
      setIsFocused(true);
    };
  


    

  return (
    <div>  <div className='search_Box'>
    <div className='d-flex gap-3'>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16.4434 16.4434L20.9996 20.9996" stroke="#2C292A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <input type='text' placeholder='Search By Student Name' onChange={(e) => { allname(e.target.value) }}
       onFocus={handleFocus}
       ></input>
    </div>
    <div className='search_btn' >
      Search
    </div>


  </div>
  <div className={`allsugesstions ${isFocused ? 'showed' : 'hideed'}`} ref={suggestionsRef} >

 
  {nameofstudents.length === 0 ? (
        <Empty />
      ) : (
        nameofstudents.map((student, index) => (
          <div className="everyitem" key={index}>
            <Link className="everyitem" to={`/studentinfo/${student?._id}`}>
              <div className="everyitem">
                {student?.fullName}
                <div className="d-flex fornav align-items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z" stroke="#696969" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke="#696969" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.41797 20.2501C5.75784 18.7588 6.59412 17.4272 7.78981 16.4733C8.98549 15.5195 10.4697 15 11.9992 15C13.5288 15 15.0129 15.5195 16.2086 16.4733C17.4043 17.4272 18.2406 18.7588 18.5805 20.2501" stroke="#696969" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  See Profile
                </div>
              </div>
            </Link>
          </div>
        ))
      )}





</div></div>
  )
}

export default Searchbar