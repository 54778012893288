import React, { useEffect, useState } from 'react'

import { Drawer, Skeleton, Switch,notification } from 'antd';
import { Button, Modal } from 'antd';
import { employeeDetail, updateemployee } from '../Services/AllFunctions';
function Employeeinfo({ studentId, setShowstu }) {
    const [activebtn, setActivebtn] = useState(1)
    const [studentinformation, setStudentinformation] = useState(true)
    const [progressinfo, setProgressinfo] = useState(false)
    const [cardinfo, setCardinfo] = useState(false)
    const changeactivebtn = (index) => {
        setActivebtn(index)
    }
    const [studentinfo, setStudentinfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const showModal = (content, name) => {
        setModalContent(content, name)
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Set loading state to true before fetching data
            try {
                const response = await employeeDetail(studentId);
                if (response.success) {
                    setStudentinfo(response.data);
                    setStatus(response.data.status)
                    setName(response.data.name)
                    setEmail(response.data.email)
                    setDateofbirth(response.data.dateofbirth)
                    setAddress(response.data.address)
                    setRole(response.data.role)
                    setPhoneNumber(response.data.phoneNumber)
                    Setemergencycontact(response.data.Emergencycontact)
                    setImageUrl(response.data.image)
                    setEmployeeId(response.data._id)
                } else {
                    console.error('Failed to fetch student:', response.data);
                }
            } catch (error) {
                console.error('Error fetching student:', error);
            } finally {
                setLoading(false); // Set loading state to false after fetching data
            }
        };

        fetchData();
    }, []);
    const [status, setStatus] = useState(true);
    const handleSwitchChange = (checked, switchIndex) => {
        if (checked) {
            // If the switch is checked, set its index as the selected status
            setStatus(switchIndex === 0);
        } else {
            // If the switch is unchecked, set the status to null
            setStatus(null);
        }
    };
    const [imageUrl, setImageUrl] = useState();

    const uploadImage = async (e) => {
        setLoading(false);
      
        const file = e.target.files[0];
      
        if (file) {
          // Check if the file type is allowed (png, jpg, jpeg)
          const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      
          if (allowedTypes.includes(file.type)) {
      
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', 'united eldt');
      
            try {
              const response = await fetch('https://api.cloudinary.com/v1_1/dgmzv5drk/image/upload', {
                method: 'POST',
                body: formData,
              });
      
              if (!response.ok) {
                throw new Error('Image upload failed');
              }
      
              const data = await response.json();
              setImageUrl(data.secure_url);
              openNotification("success")
            } catch (error) {
              console.error('Error uploading image:', error);
              errornoti('error', 'Error uploading image. Please try again.');
            } finally {
              setLoading(true);
            }
          } else {
            console.error('Invalid file type. Please choose a valid image file.');
            errornoti('error', 'Invalid file type. Please choose a valid image file.');
            setLoading(true);
          }
        }
      };
      
      
      const openNotification = (type) => {
        notification[type]({
          message: `${type.charAt(0).toUpperCase() + type.slice(1)} Data Saved`,
          description: `Your Information Is Successfully saved now move to next step choose your plan `,
          duration: 3,
        });
      };
      const errornoti = (type, des) => {
        notification[type]({
          message: `${type.charAt(0).toUpperCase() + type.slice(1)} Invalid`,
          description: des,
          duration: 3,
        });
      };

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [dateofbirth, setDateofbirth] = useState("")
    const [address, setAddress] = useState("")
    const [password, setPassword] = useState("")
    const [role, setRole] = useState("")
    const [phoneNumber, setPhoneNumber] = useState('');
    const [Emergencycontact, Setemergencycontact] = useState('');

    const handlePhoneNumberChange = (event) => {
        const input = event.target.value;

        // Remove non-numeric characters from input
        const numericInput = input.replace(/\D/g, '');

        // Apply phone number formatting
        const formattedPhoneNumber = formatPhoneNumber(numericInput);

        // Limit the input to 10 characters
        const limitedInput = formattedPhoneNumber.slice(0, 14);

        // Update the state
        setPhoneNumber(limitedInput);
    };
    const handleEmergencycontact = (event) => {
        const input = event.target.value;

        // Remove non-numeric characters from input
        const numericInput = input.replace(/\D/g, '');

        // Apply phone number formatting
        const formattedPhoneNumber = formatPhoneNumber(numericInput);

        // Limit the input to 10 characters
        const limitedInput = formattedPhoneNumber.slice(0, 14);

        // Update the state
        Setemergencycontact(limitedInput);
    };
    const formatPhoneNumber = (phoneNumber) => {
        // Apply the desired format (XXX)-XXX-XXXX
        const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]})-${match[2]}-${match[3]}`;
        }
        return phoneNumber;
    };
    const formdata = {
        name,
        email,
        dateofbirth,
        address,
        password,
        role,
        phoneNumber,
        Emergencycontact,
        image: imageUrl,
        status
    }
    const [employeeid, setEmployeeId]=useState("")
    const updateempl =()=>{
        const response = updateemployee(formdata, employeeid)
    }
    return (
        <>
            <Modal  title={null} closeIcon={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false} className='mainheadermodal'>
            <div className="headermodal">
                        <div>Update Employee</div>
                        <div>
                            <svg onClick={handleCancel} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M24.666 6.66699L6.66602 24.667" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M24.666 24.667L6.66602 6.66699" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                    </div>
                    <div className='headermodalbody'>
                <div className='modalcontent2'>Name</div>
                <input type="text" className='form-control' value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Enter Name' />
                <div className='modalcontent2'>Email</div>
                <input type="text" className='form-control' value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='Enter Email' />
                <div className='modalcontent2'>Phone Number</div>
                <input type="text" className='form-control' value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    maxLength={14} placeholder='Enter Phone Number' />
                <div className='modalcontent2'>Emergency Contact</div>
                <input type="text" className='form-control' value={Emergencycontact}
                    onChange={handleEmergencycontact}
                    maxLength={14} placeholder='Enter Emergency Number' />
                <div className='modalcontent2'>Date of Birth</div>
                <input type="date" className='form-control' value={dateofbirth} onChange={(e) => { setDateofbirth(e.target.value) }} placeholder='Date of Bith' />
                <div className='modalcontent2'>Address</div>
                <input type="text" className='form-control' value={address} onChange={(e) => { setAddress(e.target.value) }} placeholder='Enter Address' />

                <div className='modalcontent2'>Password</div>
                <input type="text" className='form-control' value={password} onChange={(e) => { setPassword(e.target.value) }} placeholder='Enter Password' />
                <div className='modalcontent2'>Role</div>
                <select style={{ width: "100%", height: "40px", borderRadius: "8px", border: "1px solid #1D1D1D" }}>
                    <option onChange={(e) => { setRole(e.target.value) }} value="Admin">Admin</option>
                    <option onChange={(e) => { setRole(e.target.value) }} value="Admin">Colaborator</option>
                    <option onChange={(e) => { setRole(e.target.value) }} value="Admin">Instructor</option>
                </select>
                <div className='addstudentbtn'>
                    <span onClick={handleCancel}>Cancel</span><span className='secondbtn' onClick={()=>{handleCancel();updateempl()}} >Save</span>
                </div>
                </div>
            </Modal>
            <div>
                 <div className='goback' onClick={() => { setShowstu(true) }} style={{ cursor: "pointer" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M11.959 6.85449L2.33398 6.85449" stroke="#696969" stroke-width="1.575" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6.27149 2.91699L2.33398 6.85449L6.27148 10.792" stroke="#696969" stroke-width="1.575" stroke-linecap="round" stroke-linejoin="round" />
                    </svg> Back
                </div> 
            </div>

            <div className='studentinfo'>
            <div className='employeenavb'>

                <div className='main_student_nav'>

                    <div className='studentimagesection'>

                        <div className='imageofstudent'>
                            {/* <div className='goback1'>
                                 Back
                            </div> */}
                            <div>
                              
                            </div>

                            <img src={studentinfo?.image} alt='image of student'></img>

                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="13.5" cy="13.5" r="13" fill="white" stroke="#F1F1F1" />
                                <path d="M13 16H11V14L17 8L19 10L13 16Z" stroke="#0A0A0A" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15.5 9.5L17.5 11.5" stroke="#0A0A0A" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18.5 13.5V19C18.5 19.1326 18.4473 19.2598 18.3536 19.3536C18.2598 19.4473 18.1326 19.5 18 19.5H8C7.86739 19.5 7.74021 19.4473 7.64645 19.3536C7.55268 19.2598 7.5 19.1326 7.5 19V9C7.5 8.86739 7.55268 8.74021 7.64645 8.64645C7.74021 8.55268 7.86739 8.5 8 8.5H13.5" stroke="#0A0A0A" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </div>
                        <span className='name_of_student'>{studentinfo?.name} </span>
                        <span className='mail_of_student'>{studentinfo?.email} </span>


                    </div>


                    <div className='togletbtn'>
                        <Switch
                            checked={status}
                            onChange={(checked) => handleSwitchChange(checked, 0)}
                        />
                        <span className={status ? 'activecolor' : ''}>Active</span>
                        <span className='leftbar'></span>

                        <Switch
                            checked={!status}
                            onChange={(checked) => handleSwitchChange(checked, 1)}
                        />
                        <span className={!status ? 'activecolor2' : ''}>Locked</span>
                        <span className='leftbar'></span>

                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M4.25781 15.7422C3.53906 15.0234 4.01563 13.5156 3.64844 12.6328C3.28125 11.75 1.875 10.9766 1.875 10C1.875 9.02344 3.26563 8.28125 3.64844 7.36719C4.03125 6.45313 3.53906 4.97656 4.25781 4.25781C4.97656 3.53906 6.48438 4.01563 7.36719 3.64844C8.25 3.28125 9.02344 1.875 10 1.875C10.9766 1.875 11.7187 3.26563 12.6328 3.64844C13.5469 4.03125 15.0234 3.53906 15.7422 4.25781C16.4609 4.97656 15.9844 6.48438 16.3516 7.36719C16.7188 8.25 18.125 9.02344 18.125 10C18.125 10.9766 16.7344 11.7187 16.3516 12.6328C15.9688 13.5469 16.4609 15.0234 15.7422 15.7422C15.0234 16.4609 13.5156 15.9844 12.6328 16.3516C11.75 16.7188 10.9766 18.125 10 18.125C9.02344 18.125 8.28125 16.7344 7.36719 16.3516C6.45313 15.9688 4.97656 16.4609 4.25781 15.7422Z" stroke="#00964E" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13.4375 8.125L8.85156 12.5L6.5625 10.3125" stroke="#00964E" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span className={status === null ? 'activecolor' : ''}>Verifield</span>
                    </div>



                  
                    {studentinformation ? (<>
                        {
                            loading ? (
                                <Skeleton />


                            ) : (
                                <>
                                    <div className='studentdata'>
                                        <div className='innerdivdata'>
                                            <div className='for_name'>
                                                <span className='name_title'>Name:</span>
                                                <span className='name_student'> {studentinfo?.name}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.name)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>

                                        <div className='innerdivdatawhite'>
                                            <div className='for_name'>
                                                <span className='name_title'>Email:</span>
                                                <span className='name_student'> {studentinfo?.email}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.email)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div className='innerdivdata'>
                                            <div className='for_name'>
                                                <span className='name_title'>Date of Birth:</span>
                                                <span className='name_student'> {studentinfo?.dateofbirth}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.dateofbirth)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>

                                        <div className='innerdivdatawhite'>
                                            <div className='for_name'>
                                                <span className='name_title'>Address:</span>
                                                <span className='name_student'> {studentinfo?.address}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.address)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div className='innerdivdata'>
                                            <div className='for_name'>
                                                <span className='name_title'>Phone:</span>
                                                <span className='name_student'> {studentinfo?.phoneNumber}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.phoneNumber)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>

                                        <div className='innerdivdatawhite'>
                                            <div className='for_name'>
                                                <span className='name_title'>Password:</span>
                                                <span className='name_student'> {studentinfo?.password}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.password)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div className='innerdivdata'>
                                            <div className='for_name'>
                                                <span className='name_title'>Role:</span>
                                                <span className='name_student'> {studentinfo?.role}</span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showModal(studentinfo.phoneNumber)} width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M10 12.5H7.5V10L15 2.5L17.5 5L10 12.5Z" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.125 4.375L15.625 6.875" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16.875 9.375V16.25C16.875 16.4158 16.8092 16.5747 16.6919 16.6919C16.5747 16.8092 16.4158 16.875 16.25 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H10.625" stroke="#0A0A0A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div className='endbtn'>

                                        </div>
                                    </div>

                                </>)
                        }
                    </>) : (<></>)}





                </div>
</div>
                <div className='diffrencer'></div>
            </div>
        </>

    )
}

export default Employeeinfo