import React, { useEffect, useState } from 'react'
import AdminNav from '../AdminDashboard/AdminNav'
import "./style.css"
import logo from "../Authenticate/Image/ELDT LOGO.svg"
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Dropdown, message, Space,  } from 'antd';
import { Switch } from 'antd';
import { fetchallemployee, fetchallstudents } from '../Services/AllFunctions';
import Searchbar from '../AdminDashboard/Searchbar';
import StudentTable from './StudentTable';
import Studentinfo from './Studentinfo';
import { Link } from 'react-router-dom';
import EmployeeTable from './employeeTable';
import Employeeinfo from './Employeeinfo';


const handleMenuClick = (e) => {
    message.info('Click on menu item.');
    console.log('click', e);
};
const items = [
    {
        label: 'Class A ',
        key: '1',

    },
    {
        label: 'Class B',
        key: '2',

    },
    {
        label: 'School Bus',
        key: '3',

        
    },
    {
        label: 'Hazmat',
        key: '3',

        
    },
    {
        label: 'Passenger',
        key: '3',

        
    },
    {
        label: 'Class A',
        key: '3',

        
    }

];
const menuProps = {
    items,
    onClick: handleMenuClick,
};
const onChange = (date, dateString) => {
    console.log(date, dateString);
};



function Employee() {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
const [studentId,setStudentId]= useState("")
const [showstu, setShowstu]=useState(true)  
    const showDrawer = () => {
        setOpen(true);
    };
    const showDrawer2 = () => {
        setOpen2(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onClose2 = () => {
        setOpen2(false);
    };
    const { RangePicker } = DatePicker;

   
  const onChange = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      const filteredData = allstudentinfo.filter(student => {
        const studentDate = new Date(student.createdAt);
        return studentDate >= startDate && studentDate <= endDate;
      });
      setAllstudentinfo(filteredData);
    }
  };
      const [allstudentinfo, setAllstudentinfo]=useState([])
      useEffect(() => {
        const fetchData = async () => {
          const response = await fetchallemployee();
          setAllstudentinfo(response.data);
        };
      
        fetchData();
      }, []);
      
      const fetchDatas = async () => {
        const response = await fetchallemployee();
        setAllstudentinfo(response.data);
      };
    return (
        <>
        {
            showstu ?(<>
            
            <Drawer title="Filtter" onClose={onClose2} open={open2} placement={"bottom"} size={"large"} >
            <div className=' filterssec gap-3 '>
            <label htmlFor="">Start Date</label>
                        <div>
                            
                            <Space direction="vertical">

                                <DatePicker onChange={onChange} picker="week" placeholder="placeholder" className='datepicker' />

                            </Space>
                        </div>
                        <label htmlFor="">Course Type</label>
                        <div>
                            
                            <Space wrap  >


                                <Dropdown menu={menuProps} className='datepicker plaec'>
                                    <Button>
                                        <Space className='plaec'>
                                            placeholder
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>

                            </Space>
                        </div>
                        <label htmlFor="">Status</label>
                        <div>
                            
                            <Space wrap >


                                <Dropdown menu={menuProps} className='datepicker'>
                                    <Button>
                                        <Space className='plaec'>
                                            placeholder
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>

                            </Space>
                        </div>
                        <div>
                            <label htmlFor="">Percentage Of Complete Course</label>
                            <Space wrap >


                                <Dropdown menu={menuProps} className='datepicker'>
                                    <Button>
                                        <Space className='plaec' >
                                            placeholder
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>

                            </Space>
                        </div>




                    </div>
      </Drawer>
            

            <div className="maincontainer">
               
              <Searchbar/>
                <div className='mobile'>


                    <div className=''>
                        <div className='tablebtn'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M3.125 10H16.875" stroke="#2C292A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10 3.125V16.875" stroke="#2C292A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Add Employees
                        </div>

                    </div>
                    <div className='liststu '>
                        <div className='tableheading'>List Of Employees</div>
                        <div className='tableheading2'>{allstudentinfo.length} <span className='inertext'>students</span></div>
                        {/* <span className='tableheading '>List of students <span>359 <span className='inertext'>students</span></span></span> */}

                    </div>
                    <div className='tablebtn2 ' onClick={showDrawer2}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M4.44703 4.5H20.5533C20.6988 4.50005 20.8411 4.5424 20.9629 4.62191C21.0847 4.70141 21.1808 4.81463 21.2395 4.94777C21.2981 5.08092 21.3167 5.22824 21.2931 5.37179C21.2695 5.51533 21.2046 5.64891 21.1064 5.75625L14.947 12.5344C14.8188 12.6716 14.7483 12.8529 14.7502 13.0406V18.3469C14.7513 18.4715 14.721 18.5944 14.662 18.7042C14.603 18.814 14.5172 18.9072 14.4127 18.975L11.4127 20.9719C11.3001 21.046 11.1697 21.0884 11.0351 21.0947C10.9005 21.101 10.7667 21.0709 10.6477 21.0076C10.5288 20.9443 10.4291 20.8501 10.3591 20.7349C10.2892 20.6198 10.2515 20.4879 10.2502 20.3531V13.0406C10.252 12.8529 10.1815 12.6716 10.0533 12.5344L3.8939 5.75625C3.7957 5.64891 3.73084 5.51533 3.70722 5.37179C3.6836 5.22824 3.70223 5.08092 3.76086 4.94777C3.81948 4.81463 3.91556 4.70141 4.03739 4.62191C4.15922 4.5424 4.30155 4.50005 4.44703 4.5V4.5Z" stroke="#0A0A0A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Add Employee
                    </div>
                    <div className='tablewraper'>
             
                       
                            {
                                allstudentinfo.map((student)=>{
                                    return(
                                        <div className='inersteudentdetail' key={student._id}>

                                         <div>
                                <span  onClick={setShowstu} className=''>Name :</span><span>{student.fullName}</span>
                            </div>
                            <div>
                                <span className=''>Email :</span><span>{student.Email}</span>
                            </div>
                            <div>
                                <span className=''>Start Date :</span><span>{student.createdAt ? new Date(student.createdAt).toISOString().split('T')[0] : "Invalid Date"}</span>

                            </div>
                         
                            <div className='tablebtn3' >

                                Details 
                            </div>
                          
                                        </div>
                                    )
                                })
                            }
                        
                       

                    </div>
                   

                </div>
                <div className=' filterssec gap-3 desktop'>
                        <div className='forfilter'>
                            <label htmlFor="">Start Date</label>
                            <Space direction="vertical">

                                <RangePicker onChange={onChange} className='datepicker' />

                            </Space>
                        </div>

                     
                       
                        




                    </div>

                <div className="table-wraper pt-4 desktop" >
                    <div className='d-flex bg-white justify-content-between align-items-center p-2'>
                        <div>
                            <span className='tableheading '>List Of Employees <span>{allstudentinfo?.length} <span className='inertext'>Employee</span></span></span>

                        </div>
                        <div>
                            <Link to="/addemployee">
                            <span className='tablebtn'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M3.125 10H16.875" stroke="#2C292A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 3.125V16.875" stroke="#2C292A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                Add Employees
                            </span>
                            </Link>
                        </div>

                    </div>
                  
                    <EmployeeTable fetchDatas={fetchDatas} allstudentinfo={allstudentinfo} setStudentId={setStudentId} setShowstu={setShowstu}/>
                </div>
            </div>
            </>):(<Employeeinfo studentId={studentId}  setShowstu={setShowstu}/>)
        }  
        </>
    )
}

export default Employee