import React, { useState } from 'react'
import "./Admin.css"
import logo from "../Authenticate/Image/ELDT LOGO.svg"
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';
import CountUp from "react-countup";

function AdminNav({ noti, activenav }) {
  const [width, setWidth] = useState(140);
  const [rotation, setRotation] = useState(0);
  const [studentId, setStudentId] = useState(0);

  const increaseWidth = () => {
    setWidth(prevWidth => (prevWidth === 140 ? 250 : 140));
    setRotation(prevRotation => prevRotation + 180);
  };
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (

    <>
      <Drawer mask={false}  closeIcon={null} style={{ marginTop: 63 }} placement="top" height="100vh" onClose={onClose} open={open} className='drawerw'>



        <div className='pages_icons'>

          <Link to="/">
            <div className={`icons_container ${activenav === 0 ? "activeclassed" : ""}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M11.875 16.2498V12.4998C11.875 12.334 11.8092 12.1751 11.6919 12.0579C11.5747 11.9406 11.4158 11.8748 11.25 11.8748H8.75C8.58424 11.8748 8.42527 11.9406 8.30806 12.0579C8.19085 12.1751 8.125 12.334 8.125 12.4998V16.2498C8.125 16.4156 8.05915 16.5745 7.94194 16.6917C7.82473 16.809 7.66576 16.8748 7.5 16.8748H3.75C3.58424 16.8748 3.42527 16.809 3.30806 16.6917C3.19085 16.5745 3.125 16.4156 3.125 16.2498V9.02324C3.1264 8.93674 3.14509 8.8514 3.17998 8.77224C3.21486 8.69308 3.26523 8.6217 3.32812 8.5623L9.57812 2.88261C9.69334 2.77721 9.84384 2.71875 10 2.71875C10.1562 2.71875 10.3067 2.77721 10.4219 2.88261L16.6719 8.5623C16.7348 8.6217 16.7851 8.69308 16.82 8.77224C16.8549 8.8514 16.8736 8.93674 16.875 9.02324V16.2498C16.875 16.4156 16.8092 16.5745 16.6919 16.6917C16.5747 16.809 16.4158 16.8748 16.25 16.8748H12.5C12.3342 16.8748 12.1753 16.809 12.0581 16.6917C11.9408 16.5745 11.875 16.4156 11.875 16.2498Z" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Home
            </div>
          </Link>
          <Link to="/students">
          <div onClick={onClose} className={`icons_container ${activenav === 2 ? "activeclassed" : ""}`}>
                  <svg xmlns="http://www.w3.org/2000/svg"  width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path d="M6 12V27" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.1641 40.4999C11.6649 38.1979 13.7164 36.3067 16.1326 34.9976C18.5489 33.6885 21.2535 33.0029 24.0016 33.0029C26.7496 33.0029 29.4543 33.6885 31.8705 34.9976C34.2867 36.3067 36.3382 38.1979 37.8391 40.4999" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M42 12L24 18L6 12L24 6L42 12Z" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M31.7438 15.4131C33.1197 16.9157 34.0288 18.7861 34.3602 20.7964C34.6916 22.8067 34.4309 24.8699 33.6101 26.7347C32.7892 28.5994 31.4436 30.185 29.7372 31.2982C28.0308 32.4115 26.0374 33.0042 24 33.0042C21.9626 33.0042 19.9692 32.4115 18.2628 31.2982C16.5564 30.185 15.2108 28.5994 14.3899 26.7347C13.5691 24.8699 13.3084 22.8067 13.6398 20.7964C13.9712 18.7861 14.8803 16.9157 16.2563 15.4131" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg> Students
                </div></Link>
                <Link to="/courses">
                <div className="icons_container ">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <path d="M3.75002 17.4746C3.74898 17.2281 3.79678 16.9838 3.89064 16.7559C3.9845 16.5279 4.12257 16.3208 4.29689 16.1465C4.4712 15.9722 4.67831 15.8341 4.90626 15.7402C5.13422 15.6464 5.3785 15.5986 5.62502 15.5996H16.25V3.09963H5.62502C5.3785 3.09859 5.13422 3.14638 4.90626 3.24025C4.67831 3.33411 4.4712 3.47218 4.29689 3.6465C4.12257 3.82081 3.9845 4.02792 3.89064 4.25587C3.79678 4.48383 3.74898 4.72811 3.75002 4.97463V17.4746Z" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M3.75 17.4746V18.0996H15" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Courses
              </div>
              </Link>
          <Link to="/employee">
          <div onClick={onClose} className={`icons_container ${activenav === 3 ? "activeclassed" : ""}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path d="M6.875 13.0996C9.11866 13.0996 10.9375 11.2808 10.9375 9.03711C10.9375 6.79345 9.11866 4.97461 6.875 4.97461C4.63134 4.97461 2.8125 6.79345 2.8125 9.03711C2.8125 11.2808 4.63134 13.0996 6.875 13.0996Z" stroke="#696969" stroke-width="1.5" stroke-miterlimit="10" />
                    <path d="M12.1406 5.12305C12.4997 5.02565 12.8701 4.97574 13.2422 4.97461C14.3196 4.97461 15.3529 5.40262 16.1148 6.16449C16.8767 6.92635 17.3047 7.95967 17.3047 9.03711C17.3047 10.1146 16.8767 11.1479 16.1148 11.9097C15.3529 12.6716 14.3196 13.0996 13.2422 13.0996" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1.25 16.0218C1.88433 15.1191 2.72659 14.3823 3.70563 13.8737C4.68467 13.3651 5.77173 13.0996 6.875 13.0996C7.97827 13.0996 9.06533 13.3651 10.0444 13.8737C11.0234 14.3823 11.8657 15.1191 12.5 16.0218" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M13.2422 13.0996C14.3455 13.0989 15.4328 13.3641 16.412 13.8727C17.3911 14.3813 18.2333 15.1184 18.8672 16.0215" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg> Employees
                </div></Link>
         
              <div onClick={onClose} className="icons_container ">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <path d="M10 2.47461V18.7246" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14.375 7.47461C14.375 7.06423 14.2942 6.65787 14.1371 6.27872C13.9801 5.89958 13.7499 5.55508 13.4597 5.2649C13.1695 4.97472 12.825 4.74453 12.4459 4.58749C12.0667 4.43044 11.6604 4.34961 11.25 4.34961H8.4375C7.6087 4.34961 6.81384 4.67885 6.22779 5.2649C5.64174 5.85095 5.3125 6.64581 5.3125 7.47461C5.3125 8.30341 5.64174 9.09827 6.22779 9.68432C6.81384 10.2704 7.6087 10.5996 8.4375 10.5996H11.875C12.7038 10.5996 13.4987 10.9288 14.0847 11.5149C14.6708 12.101 15 12.8958 15 13.7246C15 14.5534 14.6708 15.3483 14.0847 15.9343C13.4987 16.5204 12.7038 16.8496 11.875 16.8496H8.125C7.2962 16.8496 6.50134 16.5204 5.91529 15.9343C5.32924 15.3483 5 14.5534 5 13.7246" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Finances
              </div>
          

         
              {/* <div className='icons_container'>
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                  <path d="M6 12V27" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M10.1641 40.4999C11.6649 38.1979 13.7164 36.3067 16.1326 34.9976C18.5489 33.6885 21.2535 33.0029 24.0016 33.0029C26.7496 33.0029 29.4543 33.6885 31.8705 34.9976C34.2867 36.3067 36.3382 38.1979 37.8391 40.4999" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M42 12L24 18L6 12L24 6L42 12Z" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M31.7438 15.4131C33.1197 16.9157 34.0288 18.7861 34.3602 20.7964C34.6916 22.8067 34.4309 24.8699 33.6101 26.7347C32.7892 28.5994 31.4436 30.185 29.7372 31.2982C28.0308 32.4115 26.0374 33.0042 24 33.0042C21.9626 33.0042 19.9692 32.4115 18.2628 31.2982C16.5564 30.185 15.2108 28.5994 14.3899 26.7347C13.5691 24.8699 13.3084 22.8067 13.6398 20.7964C13.9712 18.7861 14.8803 16.9157 16.2563 15.4131" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Home
              </div> */}
            
        </div>
        <div className='pages_icons' >
          <Link to="/notification">   
          <div onClick={onClose} className={`icons_container ${activenav === 5 ? "activeclassed" : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M7.5 17.5H12.5" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M4.39038 8.12509C4.38933 7.38413 4.5349 6.65029 4.81868 5.96584C5.10246 5.28138 5.51885 4.65983 6.04387 4.13699C6.5689 3.61414 7.19217 3.20034 7.8778 2.91941C8.56343 2.63847 9.29787 2.49596 10.0388 2.50009C13.1326 2.52352 15.6091 5.09384 15.6091 8.1954V8.75009C15.6091 11.547 16.1951 13.172 16.7107 14.0626C16.7655 14.1574 16.7943 14.265 16.7944 14.3745C16.7945 14.4841 16.7658 14.5917 16.7112 14.6866C16.6566 14.7816 16.5781 14.8605 16.4834 14.9155C16.3886 14.9705 16.2811 14.9997 16.1716 15.0001H3.82788C3.71835 14.9997 3.61085 14.9705 3.51615 14.9155C3.42144 14.8605 3.34286 14.7816 3.28826 14.6866C3.23367 14.5917 3.20498 14.4841 3.20508 14.3745C3.20518 14.265 3.23405 14.1574 3.28881 14.0626C3.80444 13.172 4.39038 11.547 4.39038 8.75009V8.12509Z" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Notifications
                <div className='notifcationbar'>
                  <CountUp
                    end={noti}
                    start={0}
                    duration={4}
                  ></CountUp>
                </div>
              </div> </Link>
              <div className='icons_container'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 12.5C12.7614 12.5 15 10.2614 15 7.5C15 4.73858 12.7614 2.5 10 2.5C7.23858 2.5 5 4.73858 5 7.5C5 10.2614 7.23858 12.5 10 12.5Z" stroke="#696969" stroke-width="1.5" stroke-miterlimit="10" />
                  <path d="M2.42188 16.8747C3.18979 15.5444 4.2944 14.4396 5.62465 13.6715C6.9549 12.9034 8.46392 12.499 10 12.499C11.5361 12.499 13.0451 12.9034 14.3753 13.6715C15.7056 14.4396 16.8102 15.5444 17.5781 16.8747" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Settings
              </div>
          <Link to="/logout">
          <div className='icons_container'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M13.5938 6.71875L16.875 10L13.5938 13.2812" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.125 10H16.875" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.125 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H8.125" stroke="#696969" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg> Logout
                </div>
          </Link>


        </div>
      </Drawer>
      <div className='mobile nav bg-white py-3 px-3'>
        <div><img src={logo} alt="" /></div>
        <div>
          {
            open ? (<><svg xmlns="http://www.w3.org/2000/svg" onClick={onClose} width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path d="M24.666 6.66699L6.66602 24.667" stroke="#2C292A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M24.666 24.667L6.66602 6.66699" stroke="#2C292A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg></>) : (<><svg xmlns="http://www.w3.org/2000/svg" onClick={showDrawer} width="40" height="40" viewBox="0 0 40 40" fill="none">
              <path d="M6.25 20H33.75" stroke="#FBB723" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.25 10H33.75" stroke="#FBB723" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.25 30H33.75" stroke="#FBB723" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg></>)
          }

        </div>
      </div>
      <div className='navbar_of_admin desktop' style={{ width: `${width}px` }}>
        <div className='navigation_btn'>
          <div className='main_bar_logo'>
            {
              width === 140 ? (<svg xmlns="http://www.w3.org/2000/svg" width="78" height="17" viewBox="0 0 78 17" fill="none">
                <path d="M16.7949 5.72205e-06H30.9708V3.93113H22.0602V6.1945H29.6842V9.95885H22.0602V12.4605H31.1376V16.3916H16.7949V5.72205e-06Z" fill="#FBB723" />
                <path d="M32.2559 5.72205e-06H37.5212V12.1984H45.9314V16.3916H32.2559V5.72205e-06Z" fill="#FBB723" />
                <path d="M53.5081 5.72205e-06C59.2261 5.72205e-06 62.1089 2.73988 62.1089 8.1958C62.1089 13.6756 59.2499 16.3916 53.5081 16.3916H46.4082V5.72205e-06H53.5081ZM51.6735 12.4605H53.4128C55.6285 12.4605 56.7244 11.2692 56.7244 8.91055V7.48105C56.7244 5.09855 55.6285 3.93113 53.4128 3.93113H51.6735V12.4605Z" fill="#FBB723" />
                <path d="M72.64 4.1932V16.3916H67.3747V4.1932H61.9902V5.72205e-06H78.0006V4.1932H72.64Z" fill="#FBB723" />
                <path d="M15.4656 10.116C15.4656 12.2383 14.7882 13.8639 13.4562 14.9702C11.808 16.1819 9.78538 16.7735 7.74407 16.6409C5.69624 16.7692 3.6679 16.1782 2.00939 14.9702C0.654747 13.8413 0 12.2383 0 10.116V0.836673H4.98962V10.0483C4.98962 10.9288 5.21539 11.6287 5.68952 12.148C6.14107 12.6673 6.81839 12.9156 7.72149 12.9156C8.62459 12.9156 9.30192 12.6447 9.75346 12.1254C10.2523 11.5516 10.511 10.8078 10.4759 10.0483V0.836673H15.4656V10.116Z" fill="#2D2727" />
              </svg>) : (
                <img src={logo} alt='Eldt Logo'></img>
              )
            }

          </div>
          <div className='navigator' onClick={increaseWidth}>
            <svg style={{ transform: `rotate(${rotation}deg)`, transition: 'transform 1s ease-in-out' }} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="17" fill="#FFD880" stroke="#F9F9FC" stroke-width="6" />
              <path d="M14 20H20" stroke="#2D2727" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14.5 16H25.5" stroke="#2D2727" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14.5 24H25.5" stroke="#2D2727" stroke-linecap="round" stroke-linejoin="round" />
            </svg></div>

        </div>

        <div className='pages_icons'>

          <Link to="/">
            {
              width === 140 ? (<>

              
                <svg className={`${activenav === 0 ? "activeclass" : ""}`}  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M11.875 16.2498V12.4998C11.875 12.334 11.8092 12.1751 11.6919 12.0579C11.5747 11.9406 11.4158 11.8748 11.25 11.8748H8.75C8.58424 11.8748 8.42527 11.9406 8.30806 12.0579C8.19085 12.1751 8.125 12.334 8.125 12.4998V16.2498C8.125 16.4156 8.05915 16.5745 7.94194 16.6917C7.82473 16.809 7.66576 16.8748 7.5 16.8748H3.75C3.58424 16.8748 3.42527 16.809 3.30806 16.6917C3.19085 16.5745 3.125 16.4156 3.125 16.2498V9.02324C3.1264 8.93674 3.14509 8.8514 3.17998 8.77224C3.21486 8.69308 3.26523 8.6217 3.32812 8.5623L9.57812 2.88261C9.69334 2.77721 9.84384 2.71875 10 2.71875C10.1562 2.71875 10.3067 2.77721 10.4219 2.88261L16.6719 8.5623C16.7348 8.6217 16.7851 8.69308 16.82 8.77224C16.8549 8.8514 16.8736 8.93674 16.875 9.02324V16.2498C16.875 16.4156 16.8092 16.5745 16.6919 16.6917C16.5747 16.809 16.4158 16.8748 16.25 16.8748H12.5C12.3342 16.8748 12.1753 16.809 12.0581 16.6917C11.9408 16.5745 11.875 16.4156 11.875 16.2498Z" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
              </>) : (
                <div className={`icons_container ${activenav === 0 ? "activeclassed" : ""}`}
                >
                    <svg   xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M11.875 16.2498V12.4998C11.875 12.334 11.8092 12.1751 11.6919 12.0579C11.5747 11.9406 11.4158 11.8748 11.25 11.8748H8.75C8.58424 11.8748 8.42527 11.9406 8.30806 12.0579C8.19085 12.1751 8.125 12.334 8.125 12.4998V16.2498C8.125 16.4156 8.05915 16.5745 7.94194 16.6917C7.82473 16.809 7.66576 16.8748 7.5 16.8748H3.75C3.58424 16.8748 3.42527 16.809 3.30806 16.6917C3.19085 16.5745 3.125 16.4156 3.125 16.2498V9.02324C3.1264 8.93674 3.14509 8.8514 3.17998 8.77224C3.21486 8.69308 3.26523 8.6217 3.32812 8.5623L9.57812 2.88261C9.69334 2.77721 9.84384 2.71875 10 2.71875C10.1562 2.71875 10.3067 2.77721 10.4219 2.88261L16.6719 8.5623C16.7348 8.6217 16.7851 8.69308 16.82 8.77224C16.8549 8.8514 16.8736 8.93674 16.875 9.02324V16.2498C16.875 16.4156 16.8092 16.5745 16.6919 16.6917C16.5747 16.809 16.4158 16.8748 16.25 16.8748H12.5C12.3342 16.8748 12.1753 16.809 12.0581 16.6917C11.9408 16.5745 11.875 16.4156 11.875 16.2498Z" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Home
                </div>
              )
            }
          </Link>
          <Link to="/students">
            {
              width === 140 ? (<svg className={` ${activenav === 2 ? "activeclass" : ""}`} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <path d="M6 12V27" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.1641 40.4999C11.6649 38.1979 13.7164 36.3067 16.1326 34.9976C18.5489 33.6885 21.2535 33.0029 24.0016 33.0029C26.7496 33.0029 29.4543 33.6885 31.8705 34.9976C34.2867 36.3067 36.3382 38.1979 37.8391 40.4999" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M42 12L24 18L6 12L24 6L42 12Z" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M31.7438 15.4131C33.1197 16.9157 34.0288 18.7861 34.3602 20.7964C34.6916 22.8067 34.4309 24.8699 33.6101 26.7347C32.7892 28.5994 31.4436 30.185 29.7372 31.2982C28.0308 32.4115 26.0374 33.0042 24 33.0042C21.9626 33.0042 19.9692 32.4115 18.2628 31.2982C16.5564 30.185 15.2108 28.5994 14.3899 26.7347C13.5691 24.8699 13.3084 22.8067 13.6398 20.7964C13.9712 18.7861 14.8803 16.9157 16.2563 15.4131" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>) : (
                <div className={`icons_container ${activenav === 2 ? "activeclassed" : ""}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path d="M6 12V27" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.1641 40.4999C11.6649 38.1979 13.7164 36.3067 16.1326 34.9976C18.5489 33.6885 21.2535 33.0029 24.0016 33.0029C26.7496 33.0029 29.4543 33.6885 31.8705 34.9976C34.2867 36.3067 36.3382 38.1979 37.8391 40.4999" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M42 12L24 18L6 12L24 6L42 12Z" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M31.7438 15.4131C33.1197 16.9157 34.0288 18.7861 34.3602 20.7964C34.6916 22.8067 34.4309 24.8699 33.6101 26.7347C32.7892 28.5994 31.4436 30.185 29.7372 31.2982C28.0308 32.4115 26.0374 33.0042 24 33.0042C21.9626 33.0042 19.9692 32.4115 18.2628 31.2982C16.5564 30.185 15.2108 28.5994 14.3899 26.7347C13.5691 24.8699 13.3084 22.8067 13.6398 20.7964C13.9712 18.7861 14.8803 16.9157 16.2563 15.4131" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg> Students
                </div>
              )
            }</Link>
            <Link to="/courses">
          {
            width === 140 ? (<svg  className={` ${activenav === 6 ? "activeclass" : ""}`} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
              <path d="M3.75002 17.4746C3.74898 17.2281 3.79678 16.9838 3.89064 16.7559C3.9845 16.5279 4.12257 16.3208 4.29689 16.1465C4.4712 15.9722 4.67831 15.8341 4.90626 15.7402C5.13422 15.6464 5.3785 15.5986 5.62502 15.5996H16.25V3.09963H5.62502C5.3785 3.09859 5.13422 3.14638 4.90626 3.24025C4.67831 3.33411 4.4712 3.47218 4.29689 3.6465C4.12257 3.82081 3.9845 4.02792 3.89064 4.25587C3.79678 4.48383 3.74898 4.72811 3.75002 4.97463V17.4746Z" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3.75 17.4746V18.0996H15" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>) : (
              <div className={`icons_container ${activenav === 6 ? "activeclassed" : ""}`}>
                <svg  xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <path d="M3.75002 17.4746C3.74898 17.2281 3.79678 16.9838 3.89064 16.7559C3.9845 16.5279 4.12257 16.3208 4.29689 16.1465C4.4712 15.9722 4.67831 15.8341 4.90626 15.7402C5.13422 15.6464 5.3785 15.5986 5.62502 15.5996H16.25V3.09963H5.62502C5.3785 3.09859 5.13422 3.14638 4.90626 3.24025C4.67831 3.33411 4.4712 3.47218 4.29689 3.6465C4.12257 3.82081 3.9845 4.02792 3.89064 4.25587C3.79678 4.48383 3.74898 4.72811 3.75002 4.97463V17.4746Z" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M3.75 17.4746V18.0996H15" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Courses
              </div>
            )
          }
          </Link>
          <Link to="/employee">
            {
              width === 140 ? (<svg className={` ${activenav === 3 ? "activeclass" : ""}`} xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path d="M6.875 13.0996C9.11866 13.0996 10.9375 11.2808 10.9375 9.03711C10.9375 6.79345 9.11866 4.97461 6.875 4.97461C4.63134 4.97461 2.8125 6.79345 2.8125 9.03711C2.8125 11.2808 4.63134 13.0996 6.875 13.0996Z" stroke="#696969" stroke-width="1.5" stroke-miterlimit="10" />
                <path d="M12.1406 5.12305C12.4997 5.02565 12.8701 4.97574 13.2422 4.97461C14.3196 4.97461 15.3529 5.40262 16.1148 6.16449C16.8767 6.92635 17.3047 7.95967 17.3047 9.03711C17.3047 10.1146 16.8767 11.1479 16.1148 11.9097C15.3529 12.6716 14.3196 13.0996 13.2422 13.0996" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1.25 16.0218C1.88433 15.1191 2.72659 14.3823 3.70563 13.8737C4.68467 13.3651 5.77173 13.0996 6.875 13.0996C7.97827 13.0996 9.06533 13.3651 10.0444 13.8737C11.0234 14.3823 11.8657 15.1191 12.5 16.0218" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.2422 13.0996C14.3455 13.0989 15.4328 13.3641 16.412 13.8727C17.3911 14.3813 18.2333 15.1184 18.8672 16.0215" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>) : (
                <div className={`icons_container ${activenav === 3 ? "activeclassed" : ""}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path d="M6.875 13.0996C9.11866 13.0996 10.9375 11.2808 10.9375 9.03711C10.9375 6.79345 9.11866 4.97461 6.875 4.97461C4.63134 4.97461 2.8125 6.79345 2.8125 9.03711C2.8125 11.2808 4.63134 13.0996 6.875 13.0996Z" stroke="#696969" stroke-width="1.5" stroke-miterlimit="10" />
                    <path d="M12.1406 5.12305C12.4997 5.02565 12.8701 4.97574 13.2422 4.97461C14.3196 4.97461 15.3529 5.40262 16.1148 6.16449C16.8767 6.92635 17.3047 7.95967 17.3047 9.03711C17.3047 10.1146 16.8767 11.1479 16.1148 11.9097C15.3529 12.6716 14.3196 13.0996 13.2422 13.0996" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1.25 16.0218C1.88433 15.1191 2.72659 14.3823 3.70563 13.8737C4.68467 13.3651 5.77173 13.0996 6.875 13.0996C7.97827 13.0996 9.06533 13.3651 10.0444 13.8737C11.0234 14.3823 11.8657 15.1191 12.5 16.0218" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M13.2422 13.0996C14.3455 13.0989 15.4328 13.3641 16.412 13.8727C17.3911 14.3813 18.2333 15.1184 18.8672 16.0215" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg> Employees
                </div>
              )
            }</Link>
          {
            width === 140 ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
              <path d="M10 2.47461V18.7246" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14.375 7.47461C14.375 7.06423 14.2942 6.65787 14.1371 6.27872C13.9801 5.89958 13.7499 5.55508 13.4597 5.2649C13.1695 4.97472 12.825 4.74453 12.4459 4.58749C12.0667 4.43044 11.6604 4.34961 11.25 4.34961H8.4375C7.6087 4.34961 6.81384 4.67885 6.22779 5.2649C5.64174 5.85095 5.3125 6.64581 5.3125 7.47461C5.3125 8.30341 5.64174 9.09827 6.22779 9.68432C6.81384 10.2704 7.6087 10.5996 8.4375 10.5996H11.875C12.7038 10.5996 13.4987 10.9288 14.0847 11.5149C14.6708 12.101 15 12.8958 15 13.7246C15 14.5534 14.6708 15.3483 14.0847 15.9343C13.4987 16.5204 12.7038 16.8496 11.875 16.8496H8.125C7.2962 16.8496 6.50134 16.5204 5.91529 15.9343C5.32924 15.3483 5 14.5534 5 13.7246" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>) : (
              <div className="icons_container ">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <path d="M10 2.47461V18.7246" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14.375 7.47461C14.375 7.06423 14.2942 6.65787 14.1371 6.27872C13.9801 5.89958 13.7499 5.55508 13.4597 5.2649C13.1695 4.97472 12.825 4.74453 12.4459 4.58749C12.0667 4.43044 11.6604 4.34961 11.25 4.34961H8.4375C7.6087 4.34961 6.81384 4.67885 6.22779 5.2649C5.64174 5.85095 5.3125 6.64581 5.3125 7.47461C5.3125 8.30341 5.64174 9.09827 6.22779 9.68432C6.81384 10.2704 7.6087 10.5996 8.4375 10.5996H11.875C12.7038 10.5996 13.4987 10.9288 14.0847 11.5149C14.6708 12.101 15 12.8958 15 13.7246C15 14.5534 14.6708 15.3483 14.0847 15.9343C13.4987 16.5204 12.7038 16.8496 11.875 16.8496H8.125C7.2962 16.8496 6.50134 16.5204 5.91529 15.9343C5.32924 15.3483 5 14.5534 5 13.7246" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Finances
              </div>
            )
          }

          {/* {
            width === 140 ? (<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <path d="M6 12V27" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10.1641 40.4999C11.6649 38.1979 13.7164 36.3067 16.1326 34.9976C18.5489 33.6885 21.2535 33.0029 24.0016 33.0029C26.7496 33.0029 29.4543 33.6885 31.8705 34.9976C34.2867 36.3067 36.3382 38.1979 37.8391 40.4999" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M42 12L24 18L6 12L24 6L42 12Z" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M31.7438 15.4131C33.1197 16.9157 34.0288 18.7861 34.3602 20.7964C34.6916 22.8067 34.4309 24.8699 33.6101 26.7347C32.7892 28.5994 31.4436 30.185 29.7372 31.2982C28.0308 32.4115 26.0374 33.0042 24 33.0042C21.9626 33.0042 19.9692 32.4115 18.2628 31.2982C16.5564 30.185 15.2108 28.5994 14.3899 26.7347C13.5691 24.8699 13.3084 22.8067 13.6398 20.7964C13.9712 18.7861 14.8803 16.9157 16.2563 15.4131" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>) : (
              <div className='icons_container'>
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                  <path d="M6 12V27" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M10.1641 40.4999C11.6649 38.1979 13.7164 36.3067 16.1326 34.9976C18.5489 33.6885 21.2535 33.0029 24.0016 33.0029C26.7496 33.0029 29.4543 33.6885 31.8705 34.9976C34.2867 36.3067 36.3382 38.1979 37.8391 40.4999" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M42 12L24 18L6 12L24 6L42 12Z" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M31.7438 15.4131C33.1197 16.9157 34.0288 18.7861 34.3602 20.7964C34.6916 22.8067 34.4309 24.8699 33.6101 26.7347C32.7892 28.5994 31.4436 30.185 29.7372 31.2982C28.0308 32.4115 26.0374 33.0042 24 33.0042C21.9626 33.0042 19.9692 32.4115 18.2628 31.2982C16.5564 30.185 15.2108 28.5994 14.3899 26.7347C13.5691 24.8699 13.3084 22.8067 13.6398 20.7964C13.9712 18.7861 14.8803 16.9157 16.2563 15.4131" stroke="#696969" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Home
              </div>
            )
          } */}
        </div>
        <div className='pages_icons' >
          <Link to="/notification">    {
            width === 140 ? (

              <div className={`d-flex gap-2 ms-5 ${activenav === 5 ? "activeclass " : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M7.5 17.5H12.5" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M4.39038 8.12509C4.38933 7.38413 4.5349 6.65029 4.81868 5.96584C5.10246 5.28138 5.51885 4.65983 6.04387 4.13699C6.5689 3.61414 7.19217 3.20034 7.8778 2.91941C8.56343 2.63847 9.29787 2.49596 10.0388 2.50009C13.1326 2.52352 15.6091 5.09384 15.6091 8.1954V8.75009C15.6091 11.547 16.1951 13.172 16.7107 14.0626C16.7655 14.1574 16.7943 14.265 16.7944 14.3745C16.7945 14.4841 16.7658 14.5917 16.7112 14.6866C16.6566 14.7816 16.5781 14.8605 16.4834 14.9155C16.3886 14.9705 16.2811 14.9997 16.1716 15.0001H3.82788C3.71835 14.9997 3.61085 14.9705 3.51615 14.9155C3.42144 14.8605 3.34286 14.7816 3.28826 14.6866C3.23367 14.5917 3.20498 14.4841 3.20508 14.3745C3.20518 14.265 3.23405 14.1574 3.28881 14.0626C3.80444 13.172 4.39038 11.547 4.39038 8.75009V8.12509Z" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div className='notifcationbar'>
                  <CountUp
                    end={noti}
                    start={0}
                    duration={4}
                  ></CountUp>

                </div>
              </div>



            ) : (
              <div className={`icons_container ${activenav === 5 ? "activeclassed" : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M7.5 17.5H12.5" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M4.39038 8.12509C4.38933 7.38413 4.5349 6.65029 4.81868 5.96584C5.10246 5.28138 5.51885 4.65983 6.04387 4.13699C6.5689 3.61414 7.19217 3.20034 7.8778 2.91941C8.56343 2.63847 9.29787 2.49596 10.0388 2.50009C13.1326 2.52352 15.6091 5.09384 15.6091 8.1954V8.75009C15.6091 11.547 16.1951 13.172 16.7107 14.0626C16.7655 14.1574 16.7943 14.265 16.7944 14.3745C16.7945 14.4841 16.7658 14.5917 16.7112 14.6866C16.6566 14.7816 16.5781 14.8605 16.4834 14.9155C16.3886 14.9705 16.2811 14.9997 16.1716 15.0001H3.82788C3.71835 14.9997 3.61085 14.9705 3.51615 14.9155C3.42144 14.8605 3.34286 14.7816 3.28826 14.6866C3.23367 14.5917 3.20498 14.4841 3.20508 14.3745C3.20518 14.265 3.23405 14.1574 3.28881 14.0626C3.80444 13.172 4.39038 11.547 4.39038 8.75009V8.12509Z" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Notifications
                <div className='notifcationbar'>
                  <CountUp
                    end={noti}
                    start={0}
                    duration={4}
                  ></CountUp>

                </div>
              </div>
            )
          }  </Link>
          <Link to="/Userinfo">
          {
                 
            width === 140 ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 12.5C12.7614 12.5 15 10.2614 15 7.5C15 4.73858 12.7614 2.5 10 2.5C7.23858 2.5 5 4.73858 5 7.5C5 10.2614 7.23858 12.5 10 12.5Z" stroke="#696969" stroke-width="1.5" stroke-miterlimit="10" />
              <path d="M2.42188 16.8747C3.18979 15.5444 4.2944 14.4396 5.62465 13.6715C6.9549 12.9034 8.46392 12.499 10 12.499C11.5361 12.499 13.0451 12.9034 14.3753 13.6715C15.7056 14.4396 16.8102 15.5444 17.5781 16.8747" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>) : (
              <div className='icons_container'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 12.5C12.7614 12.5 15 10.2614 15 7.5C15 4.73858 12.7614 2.5 10 2.5C7.23858 2.5 5 4.73858 5 7.5C5 10.2614 7.23858 12.5 10 12.5Z" stroke="#696969" stroke-width="1.5" stroke-miterlimit="10" />
                  <path d="M2.42188 16.8747C3.18979 15.5444 4.2944 14.4396 5.62465 13.6715C6.9549 12.9034 8.46392 12.499 10 12.499C11.5361 12.499 13.0451 12.9034 14.3753 13.6715C15.7056 14.4396 16.8102 15.5444 17.5781 16.8747" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg> Settings
              </div>
            )
          }
          </Link>
          <Link to="/logout">
            {
              width === 140 ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M13.5938 6.71875L16.875 10L13.5938 13.2812" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.125 10H16.875" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.125 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H8.125" stroke="#696969" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>) : (
                <div className='icons_container'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M13.5938 6.71875L16.875 10L13.5938 13.2812" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.125 10H16.875" stroke="#696969" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.125 16.875H3.75C3.58424 16.875 3.42527 16.8092 3.30806 16.6919C3.19085 16.5747 3.125 16.4158 3.125 16.25V3.75C3.125 3.58424 3.19085 3.42527 3.30806 3.30806C3.42527 3.19085 3.58424 3.125 3.75 3.125H8.125" stroke="#696969" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg> Logout
                </div>
              )
            }
          </Link>

        </div>
      </div>

    </>

  )
}

export default AdminNav