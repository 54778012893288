import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';
import './responsive.css';
import Login from './Authenticate/Login';
import AdminNav from './AdminDashboard/AdminNav';
import Student from './Students/Student';
import HomePage from './AdminDashboard/HomePage';
import Employee from './Students/Employee';
import Collaborators from './Students/Collaborators';
import Searchstudent from './Students/Searchstudent';
import PrivateRoute from './AdminDashboard/RouteProtector/PrivateRoute';
import { jwtDecode } from 'jwt-decode';
import NotFound from './AdminDashboard/RouteProtector/NotFound';
import AddStudent from './Students/AddStudent';
import Notifiyer from "./Notifyer";
import axios from 'axios';
import { SERVER_URL } from './Constant/Constant';
import LogoutPage from './Authenticate/LogoutPage';
import PopularCourses from './Courses/PopularCourses';
import EnrollStudent from './EnrollStudent';
import StuTable from './AdminDashboard/StuTable';
import { getnotificationcount } from './Services/AllFunctions';
import Boxes from './Boxes';
import Userinfo from './Students/Userinfo';

function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [activenav, setActivenav] = useState(0);
  const [noti, setNoti] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("userId");
    if (token) {
      try {
        const userinfo = jwtDecode(token);
        setUser(userinfo);
        setIsLoggedIn(true);
        fetchnoticount(userinfo); // Assuming `userinfo` is needed for this function
      } catch (error) {
        console.error('Invalid token:', error);
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
    
    fetchnotify(); // Call this function regardless of token status, if needed
    
  }, [navigate]);

  
  useEffect(() => {
    updateActiveNav(window.location.pathname);
  }, [window.location.pathname]);
  const updateActiveNav = (pathname) => {
    console.log(pathname)
    if (pathname === '/') {
      setActivenav(0);

    } else if (pathname === '/employee') {
      setActivenav(3);
    } else if (pathname === '/students') {

      setActivenav(2);

    } else if (pathname === '/addemployee') {

      setActivenav(3);

    } else if (pathname === '/notification') {

      setActivenav(5);

    } else if (pathname === '/studentinfo/:id') {

      setActivenav(2);

    } else if (pathname === '/addstudent') {

      setActivenav(2);

    } else if (pathname === '/courses') {

      setActivenav(6);

    } else {
      // Set default value for other routes
      setActivenav(0);

    }
  };
  const isLoginPage = () => {
    return window.location.pathname === '/login';
  };

  const fetchnotify = async() => {
   await axios.get(`${SERVER_URL}/api/students/last48hours`)
      .then(res => {
        setNoti(res.data);
      })
      .catch(error => {
        console.error('Error fetching notification:', error);
      });
  };
  const [noticount,setNoticount]=useState(0)
  const fetchnoticount = async(user) => {
    if(user != null){
       const res = await getnotificationcount(user)
setNoticount(res.data.count)
    }
     
    

  };

  return (
    <>

      <div className='AdminBody'>

        {!isLoginPage() && <AdminNav activenav={activenav} noti={noticount} />}
        <Routes>
          <Route path='/login' element={<Login />} />
          {
            isLoggedIn ? (
              <>
                <Route path='/' element={<HomePage />} />
                <Route path='/students' element={<Student userRole={user?.role} />} />
                <Route path='/userinfo' element={<Userinfo/>} />
                
                <Route path='/logout' element={<LogoutPage />} />
                <Route
                  path="/addemployee"
                  element={<AddEmployee userRole={user?.role} />}
                />
                <Route
                  path="/addstudent"
                  element={<AddStudents userRole={user?.role} />}
                />
                <Route
                  path="/employee"
                  element={<Employees userRole={user?.role} />}
                />
                <Route path='/studentinfo/:id' element={<Searchstudent />} />
                <Route path='/notification' element={<Notifiyer noti={noti} user={user} fetchnoticount={fetchnoticount} />} />
                <Route path='/courses' element={<PopularCourses />} />
                <Route path='/enroll-student' element={<EnrollStudent />} />
                <Route path='/studentFiltered' element={<StuTable />} />
                <Route path='/usamap' element={<Boxes />} />
              </>
            ) : (
              <Route path='/' element={<Login />} />
            )
          }
        </Routes>
      </div>
    </>

  );
}

export default App;

const AddEmployee = ({ userRole }) => {
  if (userRole === "Admin" || userRole === "Coordinator") {
    return <Collaborators />;
  } else {
    return <NotFound />;
  }
};

const AddStudents = ({ userRole }) => {
  if (userRole === "Admin" || userRole === "Coordinator") {
    return <AddStudent />;
  } else {
    return <NotFound />;
  }
};

const Employees = ({ userRole }) => {

  if (userRole === "Admin" || userRole === "Coordinator") {
    return <Employee />;
  } else {
    return <NotFound />;
  }
};