import React from 'react'
import "./enroll.css"
import { Link } from 'react-router-dom'
function EnrollStudent() {
  return (
    <div className='enrollmentpage'>
<div >
    <div className='enrollment-heading'>Choose Your Enrollment Option</div>
    <div className='buttonoption'>
        <Link to="/courses"><button className='btnforenroll'>Enroll With Payment</button></Link> 
<Link to="/addstudent">
 <button className='btnforenroll'>Enroll For Free</button></Link>
    </div>
 
</div>

    </div>
  )
}

export default EnrollStudent