import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import logomain from "./Image/ELDT LOGO.svg"
import successmsg from "./Image/Group 6674.png"
import errormsg from "./Image/Group 6674 (2).png"
import { Modal } from "antd";
import { EmployeeLogin, RecoverEmail } from "../Services/AllFunctions";
import { SERVER_URL } from "../Constant/Constant";
import axios from "axios";

export default function LoginForm() {
 const[forget, setForget]=useState(true)
  const [email, setEmail]=useState("")
 const [password, setPassword]=useState("")
 const [failedlogin, setFailedlogin]=useState(false)
 const [successmodal, setSuccessmodal]=useState(false)
 const [success, setSuccess] = useState(false);
 const [error, setError] = useState(false);
const [process, setProcess]=useState(true)
const [loginatm, setLoginatm]=useState(true)
  const navigate = useNavigate()
const failedclose = ()=>{
  setFailedlogin(false)
}
const successclose = ()=>{
  setSuccessmodal(false)
}
const successemail = ()=>{
  setSuccess(false)
}
const removeerror =()=>{
  setError(false)
}
const gotodash =()=>{
  navigate("/");
}
const loginpage =()=>{
  setForget(true)
  setSuccess(false)
}
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginatm(false); 
  
    try {
      const response = await axios
      .post(`${SERVER_URL}/api/employeelogin`, {
        email,
        password
    })
      if (response.data.status === "true") {
        localStorage.setItem("userId", response.data.token);
        setSuccessmodal(true)
        // navigate("/studentdash");
      } else {
        setFailedlogin(true)
      }
    } catch (error) {
      // Handle the error here
      console.error("Error during login:", error);
    } finally {
      // Set loginatm back to true after handling the response
      setLoginatm(true);
    }
  };
  
const recoverEmail = async (e) => {
  e.preventDefault();
  setProcess(false);
  setError(false);
  setSuccess(false);

  try {
    const res = await RecoverEmail({email});

    if (res.data.status === false) {
      setError(true);
    } else {
      setSuccess(true);
    }
  } catch (error) {
    alert("An error occurred during email recovery");
    // Handle other errors if needed
  } finally {
    setProcess(true);
  }
};



  

 
 const handleforget =()=>{
  setForget(false)
 }

 const [typeofinput , setTypeofinput]=useState("password")
 const changeinput = ()=>{
if(typeofinput === "password"){
  setTypeofinput("text")
}else(
  setTypeofinput("password") 
)
 }
  return (
    <div className="loginform">
  <div className="inner_input_login">

        <div className="mainlog">
          <img src={logomain} alt="web-logo" style={{width:"323px"}}/>
        </div>
        {
          forget ?(
            <>
             <div className="login-form-container">
          <span className="welcome">
            Welcome 
          </span>
          <div className="logininfo">
          Employee Login
          </div>
<div className="formoflogin">
          <form>
            <div className="form-group email">
              <label>
               Email
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Ex. myemail@email.com"
                name="email"
                value={email}
                onChange={(e) => { setEmail(e.target.value.toLowerCase()) }}
                />
            </div>

            <div className="form-group password">
              <label >
                Password
              </label>
              <div className=" form-control controller">
                   <input
                type={typeofinput}
                placeholder="Ex. United2023@"
                name="password"
                value={password}
                onChange={(e)=>{setPassword(e.target.value)}}
              />
              <div style={{cursor:"pointer"}} onClick={changeinput}>
                   {
                typeofinput === "password"?(<i class="fa-solid fa-eye"></i>):(<i class="fa-solid fa-eye-slash"></i>)
              }
              </div>
           
              </div>
           
            </div>

<div className="forgert_password">
Didn’t receive your email confirmation? <div className="resendemail"> Resend email</div> 
</div>

            <button className="login_btn" type="submit" onClick={handleLogin}>
              {
                loginatm?(<> Log In</>):(<>Please Wait...</>)
              }
             
            </button>

            {/* <div className="row align-items-center">
              <div className="login-forgot-password">
                <span>Forgot your password?</span>
                <Link onClick={handleforget}>
                  <a className="login-lost-password">
                  I forgot my password
                  </a>
                </Link>
              </div>
            </div> */}
            <div onClick={handleforget} className="resendemail forgert_attempt"> Resend email</div>
          </form>
          </div>
        </div>
            </>
          ):(
            <>  

            <h2 className="logininfo forget_password">
           Forgot my password
          </h2>
            <form>
            <div className="form-group email">
              <label>
              Email
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Ex. myemail@email.com"
                value={email}
                onChange={(e) => { setEmail(e.target.value.toLowerCase()) }}
                />
            </div>
            <button className="login_btn" type="submit" onClick={recoverEmail} whileTap={{ scale: 0.9 }}>
              {
                process ?(<>
                             Password Recovery

                </>):(
                  <>
                                              Verifying....

                  </>
                )
              }
            </button>
            </form>
            </>
          )
        }
       

       </div>
      <Modal
        open={failedlogin}
        onCancel={failedclose}
        closeIcon={null}
        footer={null} 
       >
<div className="mainbody">
  <div className="imgalign">
    <img src={errormsg} alt="success"/>
  </div>
  <span className="message">Something Went Wrong Please Check your Email or password</span><br></br>
<button className="buybtn" onClick={failedclose}>Try again</button>
</div>
       </Modal>


       {/* //forget password  error*/}
       <Modal
        open={error}
        onCancel={removeerror}
        closeIcon={null}
        footer={null} 
       >
<div className="mainbody">
  <div className="imgalign">
    <img src={errormsg} alt="success"/>
  </div>
  <span className="message" style={{marginTop:"24px"}}> This Email is not been registered </span><br></br>
  <span className="exp" > Please enter the valid email to recover your account </span>
  <button className="buybtn" onClick={removeerror}>Try again</button>

</div>
       </Modal>
       <Modal
        open={successmodal}
        onCancel={successclose}
        closeIcon={null}
        footer={null} 
       >
<div className="mainbody">
  <div className="imgalign">
    <img src={successmsg} alt="success"/>
  </div>
  <span className="message">Congratulations your are successfully login</span>
  <span className="exp">Now you can start your course</span>
  <a href="/">
<button className="buybtn" >Go to Dashboard</button></a>
</div>
       </Modal>

       {/* succes sent email */}
       <Modal
        open={success}
        onCancel={successemail}
        closeIcon={null}
        footer={null} 
       >
<div className="mainbody">
  <div className="imgalign">
    <img src={successmsg} alt="success"/>
  </div>
  <span className="message" style={{marginTop:"24px"}}>Your password has been successfully sent to your email. </span><br></br>
  <span className="exp" > Please enter your email inbox and access the password reset email we sent you. With this, you will be able to change your password.</span>
<button className="buybtn" onClick={loginpage}>Go to Login</button>
</div>
       </Modal>
    </div>
  );
}



