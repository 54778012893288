import { Table } from 'antd';
import { useState, useEffect } from 'react';
import { fetchStudentInformation } from '../Services/AllFunctions'; // Assuming you have a function to fetch student information
import { Link } from 'react-router-dom';

const StuTable = () => {
  const [studentInfo, setStudentInfo] = useState({
    studentsInCurrentMonth: [],
    studentsInLastWeek: [],
    graduatedStudents: [],
    inactiveStudents: []
  });
  const [allstd, setAllstd]=useState([])
  const [lastweek, setLastweek]=useState([])
const [graduatedstu,setGraduatedstu]=useState([])
const [inactivestu,setInactivestu]=useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchStudentInformation(); // Fetch student information using your API
        setStudentInfo(data.data);
        setAllstd(data.data.studentsInCurrentMonth.reverse()) 
        setLastweek(data.data.studentsInLastWeek.reverse())  
        setGraduatedstu(data.data.graduatedStudents.reverse()) 
        setInactivestu(data.data.inactiveStudents.reverse())    
      } catch (error) {
        console.error('Error fetching student information:', error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
        title: 'Name',
        dataIndex: 'fullName',
        key: 'fullName',
        render: (text, record) => (
          <Link to={`/studentinfo/${record._id}`}><span className='underline' style={{ cursor: "pointer" }} >
            {text}
          </span></Link>  
        ),
      },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Email',
    },
    {
      title: 'Start Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => text ? new Date(text).toISOString().split('T')[0] : "Invalid Date",
    },
    {
      title: 'Course Type',
      key: 'courseType',
      render: (text, record) => (
        <span>
          {record.courseEnrollments[0].name} {record.courseEnrollments[0].language}
        </span>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (Status) => (
        (Status.Status === true ? "Active" : "Inactive")
      ),
    },

    {
      title: '% Of Completion',
      dataIndex: 'courseEnrollments',
      key: 'percent',
      render: (courseEnrollments) => (
        <div className='d-flex align-items-center gap-2'>
          <div className='studyprog'>
            <div className='realprogress' style={{ width: `${courseEnrollments[0].percent}%` }}></div>
          </div>
          {courseEnrollments[0].percent.toFixed(2)}%
        </div>
      ),
    },
    {
      title: 'More',
      key: 'Status',
      render: (Status) => (
        <div class="dropdown">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" class=" dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <path d="M16.166 17.667C16.9944 17.667 17.666 16.9954 17.666 16.167C17.666 15.3386 16.9944 14.667 16.166 14.667C15.3376 14.667 14.666 15.3386 14.666 16.167C14.666 16.9954 15.3376 17.667 16.166 17.667Z" fill="#0A0A0A" />
            <path d="M16.166 9.66699C16.9944 9.66699 17.666 8.99542 17.666 8.16699C17.666 7.33857 16.9944 6.66699 16.166 6.66699C15.3376 6.66699 14.666 7.33857 14.666 8.16699C14.666 8.99542 15.3376 9.66699 16.166 9.66699Z" fill="#0A0A0A" />
            <path d="M16.166 25.667C16.9944 25.667 17.666 24.9954 17.666 24.167C17.666 23.3386 16.9944 22.667 16.166 22.667C15.3376 22.667 14.666 23.3386 14.666 24.167C14.666 24.9954 15.3376 25.667 16.166 25.667Z" fill="#0A0A0A" />
          </svg>
          <ul class="dropdown-menu">
            <li><span class="dropdown-item" style={{ cursor: "pointer" }}   >Edit</span></li>
            {/* <li><span className='dropdown-item'>  <Popconfirm
              title="Change Status"
              description="Are you sure to Change Status?"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => { makeinactive(Status._id) }}
            >
              <span onClick={() => { updatestude(Status.Status) }}> {Status.Status === true ? ("Inactivate") : ("Activate")}</span>
            </Popconfirm></span></li> */}
          </ul>
        </div>
      ),
    },
  ];

  return (
    <div className='fortabelstudent'>
        <div id='month'>
      <h2>Students Enrolled in Current Month</h2>
      <div className='desktop'>
      <Table
        columns={columns}
        dataSource={allstd}
        rowKey={(record) => record._id}
      /></div>
                      <div className='tablewraper mobile'>


{
    allstd.map((student) => {
        return (
            <div className='inersteudentdetail' key={student._id}>

                <div>
                    <span className=''>Name :</span><span>{student.fullName}</span>
                </div>
                <div>
                    <span className=''>Email :</span><span>{student.Email}</span>
                </div>
                <div>
                    <span className=''>Start Date :</span><span>{student.createdAt ? new Date(student.createdAt).toISOString().split('T')[0] : "Invalid Date"}</span>

                </div>
                <div>
                    <span className=''>Course Type:</span><span>{student.courseEnrollments[0].name} ({student.courseEnrollments[0].language})</span>
                </div>
                <div>
                    <span className=''>Status</span><span>{student.courseEnrollments[0].completed}</span>
                </div>
                <div>
                    <span className=''>Completion % :</span><span> &nbsp; &nbsp;{student.courseEnrollments[0].percent.toFixed()}%</span>
                </div>
             

            </div>
        )
    })
}



</div>
      </div>
<div id='week'>
      <h2>Students Enrolled in Last 7 Days</h2>
      <div className='desktop'>
      <Table
        columns={columns}
        dataSource={lastweek}
        rowKey={(record) => record._id}
      /></div>
                      <div className='tablewraper mobile'>


{
    lastweek.map((student) => {
        return (
            <div className='inersteudentdetail' key={student._id}>

                <div>
                    <span className=''>Name :</span><span>{student.fullName}</span>
                </div>
                <div>
                    <span className=''>Email :</span><span>{student.Email}</span>
                </div>
                <div>
                    <span className=''>Start Date :</span><span>{student.createdAt ? new Date(student.createdAt).toISOString().split('T')[0] : "Invalid Date"}</span>

                </div>
                <div>
                    <span className=''>Course Type:</span><span>{student.courseEnrollments[0].name} ({student.courseEnrollments[0].language})</span>
                </div>
                <div>
                    <span className=''>Status</span><span>{student.courseEnrollments[0].completed}</span>
                </div>
                <div>
                    <span className=''>Completion % :</span><span>
                        &nbsp; &nbsp;{student.courseEnrollments[0].percent.toFixed()}%</span>
                </div>
             

            </div>
        )
    })
}



</div>
</div>
<div id='graduate'>
      <h2>Graduated Students</h2>
      <div className='desktop'>
      <Table
        columns={columns}
        dataSource={graduatedstu}
        rowKey={(record) => record._id}
      /></div>
                <div className='tablewraper mobile'>


{
    graduatedstu.map((student) => {
        return (
            <div className='inersteudentdetail' key={student._id}>

                <div>
                    <span className=''>Name :</span><span>{student.fullName}</span>
                </div>
                <div>
                    <span className=''>Email :</span><span>{student.Email}</span>
                </div>
                <div>
                    <span className=''>Start Date :</span><span>{student.createdAt ? new Date(student.createdAt).toISOString().split('T')[0] : "Invalid Date"}</span>

                </div>
                <div>
                    <span className=''>Course Type:</span><span>{student.courseEnrollments[0].name} ({student.courseEnrollments[0].language})</span>
                </div>
                <div>
                    <span className=''>Status</span><span>{student.courseEnrollments[0].completed}</span>
                </div>
                <div>
                    <span className=''>Completion % :</span><span>
                        &nbsp; &nbsp;{student.courseEnrollments[0].percent.toFixed()}%</span>
                </div>
             

            </div>
        )
    })
}



</div>
</div>
<div id='inactived'>
      <h2>Inactive Students</h2>
      <div className='desktop'>

    
      <Table
        columns={columns}
        dataSource={studentInfo.inactiveStudents}
        rowKey={(record) => record._id}
      />  </div></div>
          <div className='tablewraper mobile'>


{
    studentInfo.inactiveStudents.map((student) => {
        return (
            <div className='inersteudentdetail' key={student._id}>

                <div>
                    <span className=''>Name :</span><span>{student.fullName}</span>
                </div>
                <div>
                    <span className=''>Email :</span><span>{student.Email}</span>
                </div>
                <div>
                    <span className=''>Start Date :</span><span>{student.createdAt ? new Date(student.createdAt).toISOString().split('T')[0] : "Invalid Date"}</span>

                </div>
                <div>
                    <span className=''>Course Type:</span><span>{student.courseEnrollments[0].name} ({student.courseEnrollments[0].language})</span>
                </div>
                <div>
                    <span className=''>Status</span><span>{student.courseEnrollments[0].completed}</span>
                </div>
                <div>
                    <span className=''>Completion % :</span><span>
                        &nbsp; &nbsp;{student.courseEnrollments[0].percent.toFixed()}%</span>
                </div>
             

            </div>
        )
    })
}



</div>
    </div>
  );
};

export default StuTable;
