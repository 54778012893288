import axios from "axios";

import { SERVER_URL } from "../Constant/Constant";

export const EmployeeLogin = async ({ email,password }) => {
    console.log(email)
    const data = axios
      .post(`${SERVER_URL}/api/employeelogin`, {
        email,
        password
    })
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };
  export const RecoverEmail = async ({ email }) => {
    const data = axios
      .post(`${SERVER_URL}/studentbyemail/${email}`)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };
  export const fetchStudentinfo = async () => {
    const data = axios
      .get(`${SERVER_URL}/api/students/infor`)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };
  
  export const fetchallstudents = async () => {
    const data = axios
      .get(`${SERVER_URL}/api/allstudents`)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };
  export const fetchStudentInformation = async () => {
    const data = axios
      .get(`${SERVER_URL}/api/students/informa`)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };
  export const fetchallemployee = async () => {
    const data = axios
      .get(`${SERVER_URL}/api/allemployee`)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };

  export const studentDetail = async (studentId) => {
    console.log(studentId)
    const data = axios
      .get(`${SERVER_URL}/api/studentsby/${studentId}`)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };

  export const employeeDetail = async (studentId) => {
    console.log(studentId)
    const data = axios
      .get(`${SERVER_URL}/api/employeeby/${studentId}`)
      .then(function (response) {
        console.log(response.data)
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };
    export const createEmployee = async (dataform) => {
    const data = axios
      .post(`${SERVER_URL}/api/employees`,dataform)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };
  export const getresults = async (dataform) => {
    const data = axios
      .post(`${SERVER_URL}/api/calculate-average-percentage`,dataform)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };
  export const poststudent = async (dataform) => {
    const data = axios
      .post(`${SERVER_URL}/api/enroll-student`,dataform)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };
  export const poststudentbypayment = async (dataform) => {
    const data = axios
      .post(`${SERVER_URL}/api/create-payment-transactions`,dataform)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };
  export const updateemployee = async (dataform,employeeid) => {
    const data = axios
      .post(`${SERVER_URL}/api/updateemployee/${employeeid}`,dataform)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };
  export const updatestudent = async (dataform,employeeid) => {
    const data = axios
      .put(`${SERVER_URL}/api/putstudent/${employeeid}`,dataform)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };

  export const deleteemployee = async (employeeid) => {
    const data = axios
      .delete(`${SERVER_URL}/api/deletebyid/${employeeid}`)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };

  export const getnotificationcount = async (user) => {
    const data = axios
      .get(`${SERVER_URL}/api/newlyEnrolledStudents/count?employeeId=${user.id}`)
      .then(function (response) {
        return { success: true, data: response?.data };
      })
      .catch(function (error) {
        console.log(error?.response?.data);
        return { success: false, data: error?.response?.data };
      });
    return data;
  };
  