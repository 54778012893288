import React, { useEffect, useState } from 'react'
import CountUp from "react-countup";
import logo from "../Authenticate/Image/ELDT LOGO.svg"
import { Drawer } from 'antd';
import { fetchStudentinfo, searchbyname } from '../Services/AllFunctions';
import axios from 'axios';
import { SERVER_URL } from '../Constant/Constant';
import Searchbar from './Searchbar';
import { Link } from 'react-router-dom';

function HomePage({renderPage}) {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  
 
  const onClose = () => {
    setOpen(false);
  };


  const [allstudentinfo, setAllstudentinfo]=useState({})
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchStudentinfo();
      setAllstudentinfo(response.data);
      console.log(response);
    };
  
    fetchData();
  }, []);
  const getCurrentMonth = () => {
    // Get the current date
    const currentDate = new Date();
  
    // Create an array of month names
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    // Get the current month index
    const currentMonthIndex = currentDate.getMonth(); // 0 (January) to 11 (December)
  
    // Get the first 3 letters of the current month
    return monthNames[currentMonthIndex];
  };
  
  // Usage
  const currentMonth = getCurrentMonth();

  return (
    <>
   
    <div className='mainhome_dash'>
      
      <div className='Inner_Content'>
  
      <div className='Inner_Content'>
       
      <Searchbar renderPage={renderPage}/>

      <div className='AllStudent'>
        <span className='student_Data'>
          Student Data</span>
          <Link to="/studentFiltered">
          <span className='see_more'>
          See more</span>
          </Link>
          </div>
      <div className='student_info'>
      <a href="/studentFiltered#inactived">
        <div className='student_info_box'>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z" stroke="#FBB723" stroke-width="1.5" stroke-miterlimit="10" />
              <path d="M14.5703 5.42813C15.0013 5.31124 15.4457 5.25136 15.8922 5.25C17.1851 5.25 18.4251 5.76361 19.3393 6.67785C20.2536 7.59209 20.7672 8.83207 20.7672 10.125C20.7672 11.4179 20.2536 12.6579 19.3393 13.5721C18.4251 14.4864 17.1851 15 15.8922 15" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M1.5 18.5066C2.2612 17.4234 3.27191 16.5393 4.44676 15.9289C5.6216 15.3186 6.92608 15 8.25 15C9.57392 15 10.8784 15.3186 12.0532 15.9289C13.2281 16.5393 14.2388 17.4234 15 18.5066" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15.8906 15C17.2147 14.9992 18.5194 15.3174 19.6944 15.9277C20.8693 16.5381 21.8799 17.4225 22.6406 18.5063" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div className='numberofstudent'>
            <CountUp
              end={allstudentinfo?.countStudentsInCurrentMonth}
              start={0}
              duration={4}
            ></CountUp>
          </div>
          <div className='info_about_enrollment'>
            Enrolled students {currentMonth} 2024
          </div>
        </div></a>
        <div className='student_info_box'>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M3.25 6V13.5" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M5.33203 20.2504C6.08246 19.0994 7.1082 18.1538 8.31631 17.4993C9.52443 16.8447 10.8767 16.502 12.2508 16.502C13.6248 16.502 14.9771 16.8447 16.1853 17.4993C17.3934 18.1538 18.4191 19.0994 19.1695 20.2504" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M21.25 6L12.25 9L3.25 6L12.25 3L21.25 6Z" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M16.1219 7.70703C16.8099 8.45832 17.2644 9.39355 17.4301 10.3987C17.5958 11.4038 17.4655 12.4355 17.055 13.3678C16.6446 14.3002 15.9718 15.093 15.1186 15.6496C14.2654 16.2062 13.2687 16.5026 12.25 16.5026C11.2313 16.5026 10.2346 16.2062 9.38141 15.6496C8.52822 15.093 7.8554 14.3002 7.44497 13.3678C7.03453 12.4355 6.90422 11.4038 7.06991 10.3987C7.23561 9.39355 7.69015 8.45832 8.37813 7.70703" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div className='numberofstudent'>
            <CountUp
              end={allstudentinfo?.countGraduatedStudents}
              start={0}
              duration={4}
            ></CountUp>
          </div>
          <div className='info_about_enrollment'>
            Total graduated students 2024
          </div>
        </div>
        <div className='student_info_box'>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M12.25 21C17.2206 21 21.25 16.9706 21.25 12C21.25 7.02944 17.2206 3 12.25 3C7.27944 3 3.25 7.02944 3.25 12C3.25 16.9706 7.27944 21 12.25 21Z" stroke="#FBB723" stroke-width="1.65625" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8.875 11.25C9.49632 11.25 10 10.7463 10 10.125C10 9.50368 9.49632 9 8.875 9C8.25368 9 7.75 9.50368 7.75 10.125C7.75 10.7463 8.25368 11.25 8.875 11.25Z" fill="#FBB723" />
              <path d="M15.625 11.25C16.2463 11.25 16.75 10.7463 16.75 10.125C16.75 9.50368 16.2463 9 15.625 9C15.0037 9 14.5 9.50368 14.5 10.125C14.5 10.7463 15.0037 11.25 15.625 11.25Z" fill="#FBB723" />
              <path d="M16.1496 14.25C15.7531 14.9331 15.1842 15.5 14.4998 15.8941C13.8153 16.2882 13.0394 16.4956 12.2496 16.4956C11.4598 16.4956 10.6839 16.2882 9.99945 15.8941C9.31501 15.5 8.74609 14.9331 8.34961 14.25" stroke="#FBB723" stroke-width="1.65625" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div className='numberofstudent'>
            <CountUp
              end={allstudentinfo?.countStudentsInLastWeek}
              start={0}
              duration={4}
            ></CountUp>
          </div>
          <div className='info_about_enrollment'>
            Total new students this week
          </div>
        </div>
        <div className='student_info_box'>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M12.25 21C17.2206 21 21.25 16.9706 21.25 12C21.25 7.02944 17.2206 3 12.25 3C7.27944 3 3.25 7.02944 3.25 12C3.25 16.9706 7.27944 21 12.25 21Z" stroke="#FBB723" stroke-width="1.5" stroke-miterlimit="10" />
              <path d="M8.875 11.25C9.49632 11.25 10 10.7463 10 10.125C10 9.50368 9.49632 9 8.875 9C8.25368 9 7.75 9.50368 7.75 10.125C7.75 10.7463 8.25368 11.25 8.875 11.25Z" fill="#FBB723" />
              <path d="M15.625 11.25C16.2463 11.25 16.75 10.7463 16.75 10.125C16.75 9.50368 16.2463 9 15.625 9C15.0037 9 14.5 9.50368 14.5 10.125C14.5 10.7463 15.0037 11.25 15.625 11.25Z" fill="#FBB723" />
              <path d="M8.875 11.25C9.49632 11.25 10 10.7463 10 10.125C10 9.50368 9.49632 9 8.875 9C8.25368 9 7.75 9.50368 7.75 10.125C7.75 10.7463 8.25368 11.25 8.875 11.25Z" fill="#FBB723" />
              <path d="M15.625 11.25C16.2463 11.25 16.75 10.7463 16.75 10.125C16.75 9.50368 16.2463 9 15.625 9C15.0037 9 14.5 9.50368 14.5 10.125C14.5 10.7463 15.0037 11.25 15.625 11.25Z" fill="#FBB723" />
              <path d="M16.1516 16.4996C15.7551 15.8165 15.1862 15.2495 14.5017 14.8554C13.8173 14.4613 13.0414 14.2539 12.2516 14.2539C11.4618 14.2539 10.6858 14.4613 10.0014 14.8554C9.31696 15.2495 8.74804 15.8165 8.35156 16.4996" stroke="#FBB723" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div className='numberofstudent'>
            <CountUp
              end={allstudentinfo?.countInactiveStudents}
              start={0}
              duration={4}
            ></CountUp>
          </div>
          <div className='info_about_enrollment'>
            Total students inactive 30+ days
          </div>
        </div>

      </div>

    </div></div>
   </div>
   </>
  )
}

export default HomePage
