// LogoutPage.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutPage = () => {
  const history = useNavigate();

  useEffect(() => {
    // Clear local storage when component mounts
    localStorage.clear();
    // Redirect to login page
    history('/login');
  }, [history]);

  return (
  <></>
  );
};

export default LogoutPage;
