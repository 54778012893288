import React from 'react';


function Boxes() {

  // const mapHandler = (event) => {
  //   alert(event.target.dataset.name);
  // };

  const statesFilling = () => {
    return {
      "NJ": {
        fill: "navy",
        clickHandler: () => alert("Custom callback for the NJ state")
      },
      "NY": {
        fill: "#CC0000"
      },
      // Add more states as needed
    };
  };

  return (
    <div>Boxes
    
    </div>
  );
}

export default Boxes;
