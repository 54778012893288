import React from 'react'
import "./Login.css"
import LoginForm from './LoginForm'
import rightimage from "./Image/Frame 16454.png"
function Login() {
  return (
    <div className='coverof_login'>
   
    <LoginForm/>
    <div className='imageof_login'>
      <img src={rightimage} alt='login_image'></img>
    </div>

    </div>
  )
}

export default Login