import React from 'react'
import { Select,notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

const { Option } = Select;
function CourseSelection({setCourseName,setLanguage,setCourseId,addstudent,handelpayment,courseName,language,personalinfo,setSelectcourse}) {
    const currentDate = new Date();
  
  // Format the date as desired (e.g., YYYY-MM-DD)
  const formattedDate = currentDate.toISOString().slice(0, 10);
  const selectcour = (value) => {
    setCourseName(value);
    if (value === "Class A") {
      setCourseId("65a69c6841885a6096ed48c2");
    } else if (value === "Class B") {
      setCourseId("65ba8bac6423a22417c6758a");
    } else if (value === "School Bus") {
      setCourseId("65be372347e2666074233c89");
    } else if (value === "Hazmat") {
      setCourseId("65be3b2247e2666074233c8c");
    } else if (value === "Passenger") {
      setCourseId("65ca2f70083220f9daae6dee");
    }
  };
  
const selectlan =(value)=>{
  setLanguage(value)
}
const navigate = useNavigate()
const handleAddStudent = () => {
  if (courseName.trim() !== '' && language.trim() !== '') {
    // Call the addStudent function here
    addstudent(courseName, language);
  } else {
    // Display notification for empty fields
    notification.error({
      message: 'Please input all fields',
      description: 'Course Name and Language are required fields.',
    });
  }
};

  return (
    <div> 
         <div className='addmission-position dektop'>
    <div className='pagelocationdone'>
    Student info
    </div>
    <div className='dots'>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <circle cx="5" cy="5" r="5" fill="#FFD87F"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <circle cx="5" cy="5" r="5" fill="#FFD87F"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <circle cx="5" cy="5" r="5" fill="#FFD87F"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <circle cx="5" cy="5" r="5" fill="#FFD87F"/>
    </svg>
    </div>
    <div className='pagelocationdone'>
    Course
    </div>
  
        </div>
        <div className='addmission-position mobileaddstudent'>
    <div className='pagelocationdone2'>
   
    </div>
    <div className='dots'>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <circle cx="5" cy="5" r="5" fill="#FFD87F"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <circle cx="5" cy="5" r="5" fill="#FFD87F"/>
    </svg>
 
    </div>
    <div className='pagelocationdone'>
    Course
    </div>
  
        </div>
        <div className='studentdata'>
                        <div className='innerdivdata'>
                            <div className='for_name'>
                                <span className='name_title'>Type Of Program:</span>
                            </div>
                            <Select defaultValue="Select Course" onChange={selectcour} style={{ width: "70%" }}>
      <Option value="Class A">Class A</Option>
      <Option value="Class B">Class B</Option>
      <Option value="School Bus">School Bus </Option>
      <Option value="Hazmat">Hazmat</Option>
      <Option value="Passenger">Passenger</Option>
    </Select>
                        </div>
                        <div className='innerdivdatawhite'>
                            <div className='for_name'>
                                <span className='name_title'>Enrollment Date:</span>
                                <span className='name_student'> {formattedDate}</span>
                            </div>
                           
                        </div>
                        <div className='innerdivdata'>
                            <div className='for_name'>
                                <span className='name_title'>Select Language:</span>
                            </div>
                            <Select defaultValue="Select Language" onChange={selectlan} style={{ width: "70%" }}>
      <Option value="English">English</Option>
      <Option value="Spanish">Spanish</Option>
      <Option value="Portugese">Portugese </Option>
      <Option value="French">French </Option>
      <Option value="Arabic"> Arabic </Option>
      <Option value="Hindi"> Hindi </Option>
      <Option value="Urdu"> Urdu </Option>
    </Select>
                        </div>
                      

                     
                    </div>
                    <div className='conceller'>
                   <Link to="/students"><span style={{cursor:"pointer"}} onClick={()=>{setSelectcourse(false);personalinfo(true)}}>Cancel</span></Link> 
                        <div className='addstudentbtn'>
          <span style={{cursor:"pointer"}} onClick={()=>{setSelectcourse(false);personalinfo(true)}}>Previous</span><span className='secondbtn' style={{cursor:"pointer"}} onClick={handleAddStudent} >Continue</span>
        </div>
                    </div>
                  
        </div>
  )
}

export default CourseSelection